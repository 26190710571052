import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {FlexColumn, FlexSpace} from "../../Common/CommonStyle";
import {inject, observer} from "mobx-react";


const LineBox = styled(Box)(() => ({
    display:'flex',
    alignItems:'center',
    border:'1px solid #F0F1F5',
    borderRadius:8,
    padding:'8px 16px',
    '@media all and (max-width: 768px)': {
        flexDirection:'column',
        alignItems:'flex-start',
    },
}));

const RankingBox = styled(Typography)(() => ({
    width:50,
    color:'#F9CB28',
    fontSize:'1rem',
}));


const DetailBox = styled(FlexSpace)(() => ({
    flex:1,
    '@media all and (max-width: 768px)': {
        width:'100%',
    },
}));

const TxtBox = styled(FlexColumn)(() => ({
    width:'60%',
    '& .MuiTypography-subTitle1':{
        color:'#333',
        fontSize:'1rem',
        fontWeight:700,
        display: '-webkit-box',
        overflow:'hidden',
        verticalAlign:'top',
        textOverflow: 'ellipsis',
        wordBreak:'break-all',
        '-webkit-box-orient':'vertical',
        '-webkit-line-clamp':'1',
    },
    '& .MuiTypography-body1':{
        color:'#909195',
        fontSize:'0.75rem',
        fontWeight:400,
    },
}));

const NumBox = styled(Box)(() => ({
    color:'#333',
    fontSize:'1rem',
    fontWeight:600,
}));




function RankingComponent(props) {
    const { dashboardStore } = props;

    return (
                <>
                    {dashboardStore.wordStatisticsList.map((list, i) => (
                        <LineBox key={i}>
                            <RankingBox
                                style={{
                                    color: list.ranking < 4 ? '#F9CB28' : '#333333',
                                    fontWeight: list.ranking < 4 ? 600 : 400,
                                }}

                            >{list.ranking}위</RankingBox>
                            <DetailBox>
                                <TxtBox>
                                    <Typography variant={'subTitle1'}>{list.word}</Typography>
                                    <Typography variant={'body1'}>{list.titleList.map(title => title !== null ? title : '자유대화').join(', ')}</Typography>
                                </TxtBox>
                                <NumBox>{list.count}회</NumBox>
                            </DetailBox>
                        </LineBox>
                    ))}
                </>
    );
}

export default inject('dashboardStore')(observer(RankingComponent));


