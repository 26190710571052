import React, {useEffect, useState } from "react";
import {
    TableRow, TableHead, TableCell, TableBody, Box, Checkbox, Button, TextField, IconButton
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { TableStyle, Flex } from '../Common/CommonStyle';
import { inject, observer } from "mobx-react";
import FileAddDialog from './FileAddDialog';
import _ from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import CommonComfirmDialog from "../Common/CommonComfirmDialog";
import SortIcon from '@mui/icons-material/Sort';
dayjs.extend(timezone);
dayjs.extend(utc);

const Root = styled(Box)(() => ({
    width: '100%',
}));

const TableWrap = styled(Box)(() => ({
    overflowY: 'hidden',
    overflowX: 'auto',
    '& table.MuiTable-root': {
        minWidth: 1024,
    },
}));

function NprojManageTableComponents(props) {
    const {dashboardStore} = props;
    const {checkedIdList, setCheckedIdList} = props;

    const [editId, setEditId] = useState('');
    const [editValue, setEditValue] = useState('');
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [newFile, setNewFile] = useState(null);
    const [newFileName, setNewFileName] = useState('');
    const [newFileDescription, setNewFileDescription] = useState('');
    const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false);
    const [duplicateMsg, setDuplicateMsg] = useState('');

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedIdList, editId, editValue]);

    const nprojList = dashboardStore.nprojList;

    const getAllCheckStatus = () => {
        return checkedIdList.length === dashboardStore.nprojList.length;
    }

    const getDate = (createdDatetime) => {
        return dayjs(createdDatetime).tz('UTC', true).tz('Asia/Seoul').format('YYYY.MM.DD HH:mm:ss');
    }

    const handleSelectAllChange = () => {
        let newCheckedIdList = [];

        if (getAllCheckStatus()) {
            newCheckedIdList = [];
        } else {
            for (const nproj of nprojList) {
                newCheckedIdList.push(nproj.id);
            }
        }
        setCheckedIdList(newCheckedIdList);
    };

    const handleSelectChange = (targetId) => {
        let newCheckedIdList = _.cloneDeep(checkedIdList);

        if (checkedIdList.includes(targetId)) {
            newCheckedIdList = newCheckedIdList.filter(id => id !== targetId);
        } else {
            newCheckedIdList.push(targetId);
        }

        setCheckedIdList(newCheckedIdList);
    };

    const handleClickDelete = () => {
        if (checkedIdList.length > 0) {
            dashboardStore.removeNproj(checkedIdList, handleDeleteCallback);
        }
    };

    const handleDeleteCallback = () => {
        setCheckedIdList([]);
        dashboardStore.getNprojList();
    }

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    }
    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
        setNewFile(null);
        setNewFileName('');
        setNewFileDescription('');
    }

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setNewFile(file);
            setNewFileName(file.name);
        }
    };

    const handleAddNewFile = () => {
        if (newFile) {
            dashboardStore.addNproj(newFile, newFileDescription, () => dashboardStore.getNprojList(), (msg) => handleDuplicateFile(msg));
            handleCloseAddDialog();
        }
    };

    const handleDuplicateFile = (msg) => {
        setDuplicateMsg(msg);
        setOpenDuplicateDialog(true);
    }

    const handleClickEdit = (id, value) => {
        setEditId(id);
        setEditValue(value)
    };

    const handleChangeEditValue = (event) => {
        setEditValue(event.target.value);
    };

    const handleClickSave = () => {
        dashboardStore.updateNproj(editId, editValue, () => dashboardStore.getNprojList());

        setEditId('');
        setEditValue('');
    };

    const handleClickCancel = () => {
        setEditId('');
        setEditValue('');
    };

    const handleCloseDuplicateDialog = () => {
        setOpenDuplicateDialog(false);
        setDuplicateMsg('');
    }

    const handleClickDown = (nprojId) => {
        dashboardStore.downloadNproj(nprojId);
    }

    const handleSort = (column) => {
        if (dashboardStore.nprojSortColumn === column) {
            if (dashboardStore.nprojSortDirection === 'descending') {
                dashboardStore.setNprojSortDirection('ascending');
            } else {
                dashboardStore.setNprojSortDirection('descending');
            }
        } else {
            dashboardStore.setNprojSortColumn(column);
            dashboardStore.setNprojSortDirection('descending');
        }

        dashboardStore.getNprojList();
    };

    const modalContent = (
        <>
            <TextField
                margin='dense'
                id='fileName'
                label='업로드 파일명'
                type='text'
                fullWidth
                variant='standard'
                value={newFileName}
                onChange={(e) => setNewFileName(e.target.value)}
                disabled
            />
            <TextField
                margin='dense'
                id='fileDescription'
                label='파일 설명'
                type='text'
                fullWidth
                variant='standard'
                value={newFileDescription}
                onChange={(e) => setNewFileDescription(e.target.value)}
            />
        </>
    );

    return (
        <Root>
            <Flex sx={{ justifyContent: 'flex-end', marginBottom: '0.5rem' }}>
                <Button
                    onClick={handleOpenAddDialog}
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    sx={{ width: '100px' }}
                >
                    추가
                </Button>
                <Button onClick={handleClickDelete} variant="outlined" color="error" sx={{ ml: 1 }}>
                    삭제
                </Button>
            </Flex>
            <TableWrap>
                <TableStyle>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ width: '5%' }}>
                                <Checkbox
                                    checked={getAllCheckStatus()}
                                    onChange={handleSelectAllChange}
                                />
                            </TableCell>
                            <TableCell align="center" sx={{ width: '20%' }}>파일명</TableCell>
                            <TableCell align="center">설명</TableCell>
                            <TableCell align="center" sx={{ width: '15%' }}>추가 날짜</TableCell>
                            <TableCell align="center" sx={{ width: '5%' }}>
                                <Button style={{color: '#202121'}} onClick={() => handleSort('section')}>
                                    S <SortIcon />
                                </Button>
                            </TableCell>
                            <TableCell align="center" sx={{ width: '5%' }}>
                                <Button style={{color: '#202121'}} onClick={() => handleSort('owner')}>
                                    O <SortIcon />
                                </Button>
                            </TableCell>
                            <TableCell align="center" sx={{ width: '5%' }}>
                                <Button style={{color: '#202121'}} onClick={() => handleSort('book')}>
                                    B <SortIcon />
                                </Button>
                            </TableCell>
                            <TableCell align="center" sx={{ width: '10%' }}>파일 다운로드</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {nprojList.map((row, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="center">
                                    <Checkbox
                                        checked={checkedIdList.includes(row.id)}
                                        onChange={() => handleSelectChange(row.id)}
                                    />
                                </TableCell>
                                <TableCell>{row.fileName}</TableCell>
                                <TableCell sx={{position:'relative'}}>
                                    {editId === row.id ? (
                                        <>
                                            <TextField
                                                value={editValue}
                                                onChange={handleChangeEditValue}
                                                // onBlur={() => handleClickSave()}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                        handleClickSave();
                                                    } else if (event.key === 'Escape') {
                                                        handleClickCancel();
                                                    }
                                                }}
                                                sx={{width:'85%'}}
                                            />
                                            <IconButton 
                                                aria-label="save"
                                                onClick={() => handleClickSave()}
                                            >
                                                <SaveIcon />
                                            </IconButton>
                                            <IconButton 
                                                aria-label="cancel"
                                                onClick={handleClickCancel}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </>
                                    ) : (
                                        <>
                                            <span style={{
                                                overflow: 'hidden',
                                                width: '90%',
                                                display: 'inline-block',
                                                textOverflow: 'ellipsis',
                                                textWrap: 'nowrap',
                                                textAlign: 'center'
                                            }}
                                            >{row.description}</span>
                                            <IconButton
                                                aria-label="edit"
                                                onClick={() => handleClickEdit(row.id, row.description)}
                                                sx={{position:'absolute', right: '16px', top:'50%', transform:'translateY(-50%)'}}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </>
                                    )}
                                </TableCell>
                                <TableCell align="center">{getDate(row.createdDatetime)}</TableCell>
                                <TableCell align="center">{row.section}</TableCell>
                                <TableCell align="center">{row.owner}</TableCell>
                                <TableCell align="center">{row.book}</TableCell>
                                <TableCell align="center">
                                    <Button
                                        variant='outlined'
                                        color="gray"
                                        sx={{
                                            borderColor: 'gray',
                                            color: 'gray.dark',
                                            '&:hover': {
                                                backgroundColor: 'gray.light',
                                                borderColor: 'gray.dark'
                                            }
                                        }}
                                        onClick={() => handleClickDown(row.id)}
                                    >다운로드
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TableStyle>
            </TableWrap>

            <FileAddDialog
                open={openAddDialog}
                onClose={handleCloseAddDialog}
                title="nproj 업로드"
                contentText="업로드할 파일을 선택하고 파일명 및 설명을 입력하세요."
                content={modalContent}
                fileInputProps={{accept: '.nproj'}}
                onFileChange={handleFileInputChange}
                onAdd={handleAddNewFile}
                onCancel={handleCloseAddDialog}
            />

            <CommonComfirmDialog
                open={openDuplicateDialog}
                title = {'업로드 실패'}
                contents={<React.Fragment>중복되는 Nproj가 이미 존재합니다. <br/>({duplicateMsg})</React.Fragment>}
                buttonText={'확인'}
                onClick={handleCloseDuplicateDialog}
            />
        </Root>
    );
}

export default inject('dashboardStore')(observer(NprojManageTableComponents));
