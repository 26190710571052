import React, {useEffect} from "react";
import {Box, Select} from "@mui/material";
import {Flex, DateCalendarBox, Menu, SelectFormControlBox, StyledInput} from "../../Common/CommonStyle";
import { ReactComponent as ChevronDownIcon } from "../../Common/Images/ChevronDownIcon.svg";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {styled} from "@mui/material/styles";
import {inject, observer} from "mobx-react";
import dayjs from "dayjs";

const FlexBox = styled(Flex)(() => ({
    alignItems:'flex-start',
}));

const MenuBox = styled(Box)(() => ({
    width:200,
    borderRight:'1px solid #F0F1F5',
}));

const DateCalendarBoxStyle = styled(DateCalendarBox)(() => ({
    '&.MuiDateCalendar-root': {
        padding:'0 12px 12px 12px',
        '& .MuiDayCalendar-slideTransition': {
            '& .MuiPickersDay-root': {
                fontSize:'0.875rem',
            },
        },
    },
    '& .MuiPickersCalendarHeader-root':{
        minHeight:56,
        marginBottom:'0!important',
        paddingBottom:'0!important',
    },
    '& .MuiDayCalendar-weekContainer':{
        height:48,
        margin:0,
    },
    '& .MuiDayCalendar-slideTransition': {
        minHeight: '240px!important',
    },
}));
const DateWrap = styled(Box)(() => ({
    padding:'18px 24px 16px 24px',
    borderBottom:'1px solid #F0F1F5',
    boxSizing:'border-box',
}));
const DateLineBox = styled(Box)(() => ({
    width:'100%',
    minHeight:36,
    display:'flex',
    justifyContent:'space-around',
    alignItems:'center',
    fontSize:'0.875rem',
    color:'#666',
    border:'1px solid #C9C9CA',
    borderRadius:4,
    padding:'6px 16px',
    boxSizing:'border-box',
}));
const DateInput = styled(StyledInput)(({ error }) => ({
    '&.MuiFormControl-root': {
        width: 101,
    },
    '& .MuiInputBase-root': {
        minHeight: 'auto',
        height: 'auto',
        padding:'0!important',
        '& input': {
            fontSize:'0.875rem',
            color: '#666',
            textAlign : 'center',
            '&::placeholder': {
                color: '#666'
            },
            fontWeight: error ? 'bold' : null
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '0 none!important',
        },
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '0 none!important',
            }
        },
        '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '0 none!important',
            }
        },
        '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '0 none!importants',
            },
        },
    }
}));
function SelectDatepickerDialog(props) {
    const {dashboardStore} = props;
    const { width, filter, handleChangeFilter, menuList, disabled } = props;

    const [open, setOpen] = React.useState(false);
    const selectRef = React.useRef(null);

    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');

    useEffect(() => {
        const handleClickOutside = (event) => {
            const clickedEl = event.target;

            if (selectRef.current && !selectRef.current.contains(clickedEl) && clickedEl.id !== 'custom' && !clickedEl.closest('#calendarBox')) {
                setOpen(false);
            }
        };

        const handleEscKey = (event) => {
            if (event.keyCode === 27) {
                setOpen(false);
            }
        };

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleEscKey);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscKey);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [open]);

    const handleToggleSelect = () => {
        setOpen(!open);
    };

    const handleCloseSelect = () => {
        setOpen(false);
    }

    const handleClickMenu = (event, value) => {
        if (dashboardStore.chartFilterType !== value) {
            if (value === 'custom') {
                setStartDate('');
                setEndDate('');
            }
            handleChangeFilter(value);
        }
    }

    const handleClickChangeDateType = (type) => {
        dashboardStore.setChartChangeDateType(type);
    }

    const handleChangeStartDate = (newDate) => {
        setStartDate(newDate);
        if (endDate !== '') {
            dashboardStore.setChartStartDate(newDate);
            dashboardStore.setChartEndDate(endDate);
            dashboardStore.getUsageChartStatistics();
            setOpen(false);
        }
    }

    const handleChangeEndDate = (newDate) => {
        setEndDate(newDate);
        if (startDate !== '') {
            dashboardStore.setChartStartDate(startDate);
            dashboardStore.setChartEndDate(newDate);
            dashboardStore.getUsageChartStatistics();
            setOpen(false);
        }
    }

    const handleClickStartDate = () => {
        if (startDate !== '' && endDate !== '') {
            setEndDate('');
        }
        handleClickChangeDateType('start');
    }

    const handleClickEndDate = () => {
        if (startDate !== '' && endDate !== '') {
            setStartDate('');
        }
        handleClickChangeDateType('end');
    }

    const getMaxStartDate = () => {
        if (endDate !== '') {
            return endDate;
        }
        return dayjs();
    }

    const getMinEndDate = () => {
        if (startDate !== '') {
            return startDate;
        }
        return null;
    }

    const getMaxEndDate = () => {
        return dayjs();
    }

    return (
                  <SelectFormControlBox variant="outlined" sx={{width:{width}}}>
                      <Select
                          ref={selectRef}
                          id="filter"
                          value={filter}
                          disabled={disabled}
                          open={open}
                          onOpen={handleToggleSelect}
                          onClose={handleCloseSelect}
                          IconComponent={(props) => (
                              <Box>
                                  <ChevronDownIcon style={{marginTop:4, marginRight:8,}} />
                              </Box>
                          )}
                          MenuProps={{
                              anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'right'
                              },
                              transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'right'
                              },
                              PaperProps: {
                                  sx: {
                                      '&.MuiPaper-root': {
                                          boxShadow:' 0px 0px 4px rgba(0, 0, 0, 0.15);',
                                          border: '0',
                                          borderRadius:'4px',
                                          boxSizing: 'border-box',
                                          marginTop: '4px',
                                          '& ul': {
                                              padding: 0
                                          }
                                      }
                                  }
                              },
                          }}
                      >
                          {menuList.map((list, i) => (
                              <Menu style={{display: 'none'}} key={i} value={list.value} disableRipple>
                                  {dashboardStore.chartStartDate !== '' ? dashboardStore.chartStartDate.format("YYYY/MM/DD") : '0000/00/00'} - {dashboardStore.chartEndDate !== '' ? dashboardStore.chartEndDate.format("YYYY/MM/DD") : '0000/00/00'}
                              </Menu>
                          ))}
                          <FlexBox>
                              <MenuBox>
                              {menuList.map((list, i) => (
                                  <Menu id={list.value} style={dashboardStore.chartFilterType === list.value ? {backgroundColor: '#FEEA7A'}: null} key={i} value={list.value} onClick={(event) => handleClickMenu(event, list.value)} disableRipple>
                                      {list.text}
                                  </Menu>
                              ))}
                              </MenuBox>
                              <Box id='calendarBox'>
                                  <DateWrap>
                                  <DateLineBox>
                                      <DateInput error={dashboardStore.chartFilterType === 'custom' && dashboardStore.chartChangeDateType === 'start'}
                                                 value={dashboardStore.chartFilterType !== 'custom' ? (dashboardStore.chartStartDate !== '' ? dashboardStore.chartStartDate.format("YYYY/MM/DD") : '0000/00/00') : (startDate !== '' ? startDate.format("YYYY/MM/DD") : '0000/00/00')}
                                                 disabled={dashboardStore.chartFilterType !== 'custom'} onClick={handleClickStartDate}/>
                                      -
                                      <DateInput error={dashboardStore.chartFilterType === 'custom' && dashboardStore.chartChangeDateType === 'end'}
                                                 value={dashboardStore.chartFilterType !== 'custom' ? (dashboardStore.chartEndDate !== '' ? dashboardStore.chartEndDate.format("YYYY/MM/DD") : '0000/00/00') : (endDate !== '' ? endDate.format("YYYY/MM/DD") : '0000/00/00')}
                                                 disabled={dashboardStore.chartFilterType !== 'custom'} onClick={handleClickEndDate}/>
                                  </DateLineBox>
                                  </DateWrap>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DateCalendarBoxStyle style={dashboardStore.chartChangeDateType === 'start' ? {display: 'block'} : {display: 'none'}}
                                                            onChange={(newValue) => handleChangeStartDate(newValue)}
                                                            value={dashboardStore.chartFilterType === 'custom' ? (startDate !== '' ? startDate : null) : null}
                                                            disabled={dashboardStore.chartFilterType !== 'custom'}
                                                            maxDate={getMaxStartDate()}

                                      />
                                      <DateCalendarBoxStyle style={dashboardStore.chartChangeDateType === 'end' ? {display: 'block'} : {display: 'none'}}
                                                            onChange={(newValue) => handleChangeEndDate(newValue)}
                                                            value={dashboardStore.chartFilterType === 'custom' ? (endDate !== '' ? endDate : null) : null}
                                                            disabled={dashboardStore.chartFilterType !== 'custom'}
                                                            minDate={getMinEndDate()}
                                                            maxDate={getMaxEndDate()}
                                      />
                                  </LocalizationProvider>
                              </Box>
                          </FlexBox>
                      </Select>
                  </SelectFormControlBox>
    );
}

export default inject('dashboardStore')(observer(SelectDatepickerDialog));