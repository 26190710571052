import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const DialogPaper = styled('div')({
    minWidth: '500px',
    textAlign: 'center',
    background: '#fff'
});

function CommonModal({ open, onClose, title, content, contentText, fileInputProps, onFileChange, onAdd, onCancel }) {
    return (
        <Dialog open={open} onClose={onClose} PaperComponent={DialogPaper}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {contentText}
                </DialogContentText>
                <Button
                    component="label"
                    variant="contained"
                    color="info"
                    sx={{ margin: '1.5rem 0' }}
                    size="large"
                    startIcon={<UploadIcon />}
                >
                    파일 선택
                    <VisuallyHiddenInput type="file" onChange={onFileChange} {...fileInputProps} />
                </Button>
                {content}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                    size="large"
                    variant="outlined"
                    color='gray'
                    sx={{
                        flex: '1',
                        '&:hover': {
                            borderColor: 'gray.dark'
                        }
                    }}
                >
                    취소
                </Button>
                <Button
                    onClick={onAdd}
                    size="large"
                    variant="contained"
                    color="primary"
                    sx={{
                        flex: '1'
                    }}
                >
                    추가
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CommonModal;
