import React from "react";
import {Box, Drawer, Tabs, Tab, Button, IconButton} from "@mui/material";
import { styled } from "@mui/material/styles";
import {ButtonStyle, Flex, FlexColumnCenter, FlexSpace} from '../Common/CommonStyle';
import { ReactComponent as LogoBi } from "../Common/Images/LogoBi.svg";
import { ReactComponent as CloseIcon } from "../Common/Images/CloseIcon.svg";
import BtnBanner1 from "../Common/Images/BtnBanner1.jpg";
import BtnBanner2 from "../Common/Images/BtnBanner2.jpg";
import { sideBarWidth } from './Dashboard';
import {inject, observer} from "mobx-react";
import {useHistory} from "react-router-dom";


const Root = styled(Drawer)(() => ({
    position:'relative',
    minWidth: `${sideBarWidth}px`,
    width: `${sideBarWidth}px`,
    height: '100%',
    minHeight:'100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 0,
    borderRight: '1px solid #F0F1F5',
    background: '#fff',
    '@media all and (min-width: 1280px)': {
        '& .MuiDrawer-paper': {
            position:'absolute',
            overflow:'hidden',
        },
    },
}));

const PositionBox = styled(FlexSpace)(() => ({
    position:'absolute',
    top:0,
    left:0,
    width: `${sideBarWidth}px`,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& > .MuiBox-root':{
        width:'100%',
    },
    '& .MuiTabs-flexContainer':{
        gap:16,
    },
    '& .MuiTabs-indicator':{
        width:4,
        backgroundColor:'#FDD751',
    },
}));


const TitleBox = styled(Flex)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent:'flex-start',
    '& .MuiIconButton-root':{
        display:'none',
    },
    '@media all and (max-width: 1280px)': {
        height:75,
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        boxSizing:'border-box',
        '& .MuiIconButton-root':{
            display:'flex',
            padding:12,
        },
    },
}));

const LogoBox = styled(Flex)(() => ({
    padding:'40px 0 40px 32px',
    '@media all and (max-width: 1280px)': {
        padding:24,
        '& svg':{
            width:120,
            height:27,
        },
    },
}));

const TabStyle = styled(Tab)(({ theme }) => ({
    '&.MuiTab-root':{
        minHeight:30,
        display:'flex',
        alignItems:'center',
        justifyContent:'flex-start',
        gap:16,
        border:'0 none',
        padding:'8px 32px',
        color:'#C3C3C3',
        fontSize:'1.125rem',
        fontWeight:700,
        textAlign:'left',
        textTransform: 'none',
        '&.Mui-selected, &:hover':{
            backgroundColor:'#fff',
            color:'#333',
            '& svg path':{
                fill:'#FDD751',
            },
        },
        '& svg':{
            width:30,
            height:30,
            marginRight:0,
        },
    },
}));

const Banner = styled(Button)(() => ({
    padding:'4px 0',
}));

const LogoutButton = styled(ButtonStyle)(() => ({
    color:'#c3c3c3',
    fontSize:'0.875rem',
    marginTop:24,
    '&:hover':{
        backgroundColor:'#fff',
    },
}));

function SideBar(props) {
    const {value, handleChange, lnbMenu, open, handleDrawerClose, variant} = props;

    const {authStore} = props;
    const history = useHistory();

    const handleClickLogout = () => {
        authStore.doLogout(() => history.push('/'));
    }

    const handleClickPokoroGuide = () => {
        window.open('https://pokoro.notion.site/67da6484adaa47cb93096a99373ce518?pvs=4');
    }

    const handleClickPokoroWeb = () => {
        window.open('https://board.pokoro.ai');
    }

    return (
        <Root
            open={open}
            anchor="right"
            variant={variant}
            sx={{
                display: {
                    xs: variant === 'permanent' ? 'none' : 'flex',
                    lg: variant === 'permanent' ? 'flex' : 'none',
                },
                width: sideBarWidth,
                left:'unset',
                right:0,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: sideBarWidth,
                },
                '@media all and (max-width: 1280px) and (min-width: 1200px)': {
                    display: 'none',
                },
            }}
        >
            <PositionBox>
                <Box>
                    <TitleBox>
                        <LogoBox>
                            <LogoBi />
                        </LogoBox>
                        <IconButton
                            // edge="end"
                            onClick={handleDrawerClose}
                            disableRipple
                        >
                            <CloseIcon/>
                        </IconButton>
                    </TitleBox>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                >
                    {lnbMenu.map((tab, i) => (
                        <TabStyle
                            disabled={i === 3}
                            key={i}
                            label={tab.text}
                            icon={tab.icon}
                            iconPosition="start"
                        />
                    ))}
                </Tabs>
                </Box>
                <FlexColumnCenter pb={5}>
                    <Banner disableRipple onClick={handleClickPokoroGuide}>
                        <img src={BtnBanner1} alt={'포코로 설명 바로가기'}/>
                    </Banner>
                    <Banner disableRipple onClick={handleClickPokoroWeb}>
                        <img src={BtnBanner2} alt={'포코로 보드 바로가기'}/>
                    </Banner>
                    <LogoutButton disableRipple onClick={handleClickLogout}>로그아웃</LogoutButton>
                </FlexColumnCenter>
            </PositionBox>
        </Root>
    );
}

export default inject('authStore')(observer(SideBar));


