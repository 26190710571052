import { default as AuthStore } from "./AuthStore";
import DashboardStore from "./DashboardStore";
import LoadingStore from "./LoadingStore";

import AuthRepository from "../repositories/AuthRepository";
import DashboardRepository from "../repositories/DashboardRepository";
import NcodeEditRepository from "../repositories/NcodeEditRepository";

const authRepository = new AuthRepository();
const dashboardRepository = new DashboardRepository();
const nCodeEditRepository = new NcodeEditRepository();

export const stores = {
    authStore:  new AuthStore({authRepository}),
    dashboardStore:  new DashboardStore({dashboardRepository, nCodeEditRepository}),
    loadingStore: new LoadingStore(),
};