import React from "react";
import {Box, Tooltip, Typography} from "@mui/material";
import { styled } from "@mui/material/styles";
import {Flex} from '../../Common/CommonStyle';
import {inject, observer} from "mobx-react";

const Root = styled(Box)(() => ({
    width:'100%',
    display:'flex',
    flexDirection:'column',
    gap:16,
    '& .MuiTypography-subTitle1':{
        width:230,
        color:'#333',
        fontSize:'1rem',
        fontWeight:400,
        display: '-webkit-box',
        overflow:'hidden',
        verticalAlign:'top',
        textOverflow: 'ellipsis',
        wordBreak:'break-all',
        '-webkit-box-orient':'vertical',
        '-webkit-line-clamp':'1',
    },
}));

const BoxStyle = styled(Box)(() => ({
    height:34,
    display:'flex',
    alignItems:'center',
    padding:'8px 0',
    '@media all and (max-width: 768px)': {
        flexDirection:'column',
        alignItems:'flex-start',
        marginBottom:16,
    },
}));

const FlexBox = styled(Flex)(() => ({
    '@media all and (max-width: 768px)': {
        alignItems:'flex-start',
    },
}));

const DataBox = styled(Typography)(() => ({
    minWidth:50,
    color:'#333',
    fontSize:'1rem',
    marginRight:20,
}));


const BarBox = styled(Box)(() => ({
    position:'relative',
    width:'100%',
    height:8,
    borderRadius:2,
    backgroundColor:'#F0F1F5',
    margin:'0 18px',
    '@media all and (max-width: 768px)': {
        margin:'0',
    },
}));

const ProgressBar = styled('span')(() => ({
    position:'absolute',
    top:0,
    left:0,
    height:8,
    borderRadius:'0 2px 2px 0',
    overflow:'hidden',
}));


const NumBox = styled(Box)(() => ({
    minWidth:56,
    color:'#333',
    fontSize:'1rem',
    fontWeight:400,
    textAlign:'right',
}));

const TooltipBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: 14
}));


function ProgressLinearRankingComponent(props) {
    const { dashboardStore } = props;

    let colorList = ['#FFC019', '#FF9F19', '#F94C86', '#BC7ADC', '#3EC0F0', '#5A6198', '#E76F1F', '#3B9225', '#D70000'];
    const getColor = (title) => {
        if (title === '기타') {
            return '#B7B7B7';
        }
        const randomIndex = Math.floor(Math.random() * colorList.length);
        const randomColor = colorList[randomIndex];

        colorList = colorList.filter((_, index) => index !== randomIndex);

        return randomColor;
    }

    const getMaxValue = () => {
        return Math.max(...(dashboardStore.dialogStatisticsList.map(d => d.count)));
    }

    const getProgressValue = (count) => {
        const maxValue = getMaxValue();
        return count / maxValue * 100;
    }

    return (
                <Root>
                    {dashboardStore.dialogStatisticsList.map((list, i) => (
                        <BoxStyle key={i}>
                            <FlexBox>
                                <DataBox
                                    style={{
                                        color: (i + 1) < 4 ? '#333' : '#666',
                                        fontWeight: (i + 1) < 4 ? 600 : 400,
                                    }}>
                                    {(i + 1)}위</DataBox>
                                <Tooltip
                                    componentsProps={{
                                        tooltip:{
                                            sx: {
                                                maxWidth:'500px!important',
                                                backgroundColor: '#333',
                                                padding:'16px 24px',
                                                borderRadius:1,
                                            },
                                        },
                                    }}
                                    title={list.title === '기타' ?
                                        <React.Fragment>
                                            {dashboardStore.etcDialogList.map((d, i) => {
                                                return (
                                                    <TooltipBox key={i}>
                                                        <Typography variant={'subTitle1'} style={{ marginRight: '10px' }}>• {d.title}</Typography>
                                                        <Typography variant={'subTitle1'}>{d.count}</Typography>
                                                    </TooltipBox>
                                                )
                                            })}
                                        </React.Fragment>
                                        :
                                        null
                                    } placement="bottom-start">
                                    <Typography variant={'subTitle1'}>{list.title}</Typography>
                                </Tooltip>
                            </FlexBox>
                            <FlexBox width={'100%'}>
                                <BarBox>
                                    <ProgressBar
                                        style={{
                                            width: getProgressValue(list.count) + '%' ,
                                            backgroundColor: getColor(list.title),
                                        }}
                                    />
                                </BarBox>
                                <NumBox>{list.count}</NumBox>
                            </FlexBox>
                        </BoxStyle>
                    ))}
                </Root>
    );
}

export default inject('dashboardStore')(observer(ProgressLinearRankingComponent));


