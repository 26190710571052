import {makeAutoObservable} from "mobx";
import axios from "axios";
import fileDownload from "js-file-download";
import dayjs from "dayjs";

export const DefaultModelName = 'NSP-C1000';
export const DefaultMainFileType = 'MT2523D';
export const DefaultSubFileType = 'ESP32S3';

const logPrefix  = "[DashboardStore]"
export default class DashboardStore {

    constructor(props) {
        this.dashboardRepository = props.dashboardRepository;
        this.nCodeEditRepository = props.nCodeEditRepository;

        this.isUsageLoading = false;
        this.isUsageChartLoading = false;
        this.isWordLoading = false;
        this.isDialogLoading = false;
        this.isUserLoading = false;
        this.isCSVLoading = false;
        this.isTitleLoading = false;
        this.isNprojListLoading = false;
        this.isAddNprojLoading = false;
        this.isUpdateNprojLoading = false;
        this.isRemoveNprojLoading = false;
        this.isDownNprojLoading = false;
        this.isNcp2ListLoading = false;
        this.isAddNcp2Loading = false;
        this.isUpdateNcp2Loading = false;
        this.isRemoveNcp2Loading = false;
        this.isDownNcp2Loading = false;
        this.isMainFirmwareListLoading = false;
        this.isSubFirmwareListLoading = false;
        this.isAddMainFirmwareLoading = false;
        this.isAddSubFirmwareLoading = false;
        this.isRemoveFirmwareLoading = false;
        this.isDownFirmwareLoading = false;

        this.usageStatistics = '';
        this.usageChartStatisticsList = [];
        this.wordStatisticsList = [];
        this.dialogStatisticsList = [];

        this.chartFilterType = 'today';
        this.chartStartDate = dayjs();
        this.chartEndDate = dayjs();
        this.chartChangeDateType = 'start';

        this.userStatisticsListCount = 0;
        this.userStatisticsList = [];
        this.tempSearchId = ''
        this.searchId = '';
        this.rowsPerPage = 10;
        this.page = 1;
        this.userSortColumn = 'lastUsedDatetime';
        this.userSortDirection = 'descending';

        this.wordFilterType = 'daily';
        this.dialogFilterType = 'daily';
        this.etcDialogList = [];

        this.csvSearchUserId = '';
        this.csvSearchType = '';
        this.csvSearchStartDate = '';
        this.csvSearchEndDate = '';
        this.csvSearchTitle = '';
        this.csvSearchWordList = [];
        this.csvSearchWordInput = '';
        this.titleList = [];

        this.nprojListCount = 0;
        this.nprojList = [];
        this.nprojRowsPerPage = 10;
        this.nprojPage = 1;

        this.ncp2ListCount = 0;
        this.ncp2List = [];
        this.ncp2RowsPerPage = 10;
        this.ncp2Page = 1;

        this.mainFirmwareListCount = 0;
        this.mainFirmwareList = [];
        this.mainFirmwareRowsPerPage = 10;
        this.mainFirmwarePage = 1;

        this.subFirmwareListCount = 0;
        this.subFirmwareList = [];
        this.subFirmwareRowsPerPage = 10;
        this.subFirmwarePage = 1;

        this.nprojSortColumn = '';
        this.nprojSortDirection = '';

        makeAutoObservable(this);
    }

    init = () => {
        this.usageStatistics = '';
        this.usageChartStatisticsList = [];
        this.wordStatisticsList = [];
        this.dialogStatisticsList = [];

        this.chartFilterType = 'today';
        this.chartStartDate = dayjs();
        this.chartEndDate = dayjs();
        this.chartChangeDateType = 'start';

        this.userStatisticsListCount = 0;
        this.userStatisticsList = [];
        this.tempSearchId = ''
        this.searchId = '';
        this.rowsPerPage = 10;
        this.page = 1;
        this.userSortColumn = 'lastUsedDatetime';
        this.userSortDirection = 'descending';

        this.wordFilterType = 'daily';
        this.dialogFilterType = 'daily';
        this.etcDialogList = [];

        this.nprojListCount = 0;
        this.nprojList = [];
        this.nprojRowsPerPage = 10;
        this.nprojPage = 1;

        this.ncp2ListCount = 0;
        this.ncp2List = [];
        this.ncp2RowsPerPage = 10;
        this.ncp2Page = 1;

        this.mainFirmwareListCount = 0;
        this.mainFirmwareList = [];
        this.mainFirmwareRowsPerPage = 10;
        this.mainFirmwarePage = 1;

        this.subFirmwareListCount = 0;
        this.subFirmwareList = [];
        this.subFirmwareRowsPerPage = 10;
        this.subFirmwarePage = 1;

        this.nprojSortColumn = '';
        this.nprojSortDirection = '';
    }

    initCSVSearch = () => {
        this.csvSearchUserId = '';
        this.csvSearchType = '';
        this.csvSearchStartDate = '';
        this.csvSearchEndDate = '';
        this.csvSearchTitle = '';
        this.csvSearchWordList = [];
        this.csvSearchWordInput = '';
        this.getTitleList();
    }

    get getIsLoading() {
        return this.isUsageLoading || this.isUsageChartLoading || this.isWordLoading || this.isDialogLoading || this.isUserLoading || this.isTitleLoading
            || this.isNprojListLoading || this.isAddNprojLoading || this.isUpdateNprojLoading || this.isRemoveNprojLoading || this.isDownNprojLoading
            || this.isNcp2ListLoading || this.isAddNcp2Loading || this.isUpdateNcp2Loading || this.isRemoveNcp2Loading || this.isDownNcp2Loading
            || this.isMainFirmwareListLoading || this.isSubFirmwareListLoading || this.isAddMainFirmwareLoading || this.isAddSubFirmwareLoading
            || this.isRemoveFirmwareLoading || this.isDownFirmwareLoading;
    };

    setTempSearchId = (id) => {
        this.tempSearchId = id;
    }

    setSearchId = (id) => {
        this.searchId = id;
    }

    setPage = (page) => {
        this.page = page;
    }

    setCSVSearchUserId = (userId) => {
        this.csvSearchUserId = userId;
    }

    setCSVSearchType = (type) => {
        this.csvSearchType = type;
        const today = dayjs();

        if (type !== 'custom') {
            this.csvSearchEndDate = today.subtract(1, 'day');
        }

        if (type === 'today') {
            this.csvSearchStartDate = today;
            this.csvSearchEndDate = today;
        } else if (type === 'weekend') {
            // let currentDay = today.day();
            //
            // if (currentDay === 0) {
            //     currentDay = 7;
            // }
            // const lastSunday = today.subtract(currentDay, 'day');
            // const lastMonday = lastSunday.subtract(6, 'day');

            this.csvSearchStartDate = today.subtract(7, 'day');
            // this.csvSearchEndDate = lastSunday;
        } else if (type === 'month') {
            // const lastMonthStart = today.subtract(1, 'month').startOf('month');
            // const lastMonthEnd = today.subtract(1, 'month').endOf('month');

            this.csvSearchStartDate = today.subtract(30, 'day');
            // this.csvSearchEndDate = lastMonthEnd;
        } else if (type === 'quarter') {
            // const lastQuarterStart = today.subtract(3, 'month').startOf('month');
            // const lastQuarterEnd = today.subtract(1, 'month').endOf('month');

            this.csvSearchStartDate = today.subtract(90, 'day');
            // this.csvSearchEndDate = lastQuarterEnd;
        }
    }

    setCSVSearchStartDate = (date) => {
        this.csvSearchStartDate = date;
    }

    setCSVSearchEndDate = (date) => {
        this.csvSearchEndDate = date;
    }

    setCSVSearchTitle = (title) => {
        this.csvSearchTitle = title;
    }

    setCSVSearchWordList = (wordList) => {
        this.csvSearchWordList = wordList;
    }

    setCSVSearchWordInput = (word) => {
        this.csvSearchWordInput = word;
    }

    setChartFilterType = (type) => {
        this.chartFilterType = type;

        const today = dayjs();
        let currentDay = today.day();
        if (currentDay === 0) {
            currentDay = 7;
        }

         if (type === 'today') {
             this.chartStartDate = today;
             this.chartEndDate = today;
        } else if (type === 'yesterday') {
             const yesterday = today.subtract(1, 'day');
             this.chartStartDate = yesterday;
             this.chartEndDate = yesterday;
        } else if (type === 'thisWeek') {
             const thisMonday = today.subtract(currentDay - 1, 'day');
             const thisSunday = thisMonday.add(6, 'day');

             this.chartStartDate = thisMonday;
             this.chartEndDate = thisSunday;
        } else if (type === 'lastWeek') {
             const lastSunday = today.subtract(currentDay, 'day');
             const lastMonday = lastSunday.subtract(6, 'day');

             this.chartStartDate = lastMonday;
             this.chartEndDate = lastSunday;
        } else if (type === 'last7Day') {
             this.chartStartDate = today.subtract(7, 'day');
             this.chartEndDate = today.subtract(1, 'day');
        } else if (type === 'last14Day') {
             this.chartStartDate = today.subtract(14, 'day');
             this.chartEndDate = today.subtract(1, 'day');
        } else if (type === 'last30Day') {
             this.chartStartDate = today.subtract(30, 'day');
             this.chartEndDate = today.subtract(1, 'day');
        } else if (type === 'last60Day') {
             this.chartStartDate = today.subtract(60, 'day');
             this.chartEndDate = today.subtract(1, 'day');
        } else if (type === 'last90Day') {
             this.chartStartDate = today.subtract(90, 'day');
             this.chartEndDate = today.subtract(1, 'day');
        } else if (type === 'last12Month') {
             this.chartStartDate = today.subtract(12, 'month').startOf('month');
             this.chartEndDate = today.subtract(1, 'month').endOf('month');
        } else if (type === 'thisYear') {
             this.chartStartDate = today.startOf('year');
             this.chartEndDate = today;
        } else if (type === 'lastYear') {
             this.chartStartDate = today.subtract(1, 'year').startOf('year');
             this.chartEndDate = today.subtract(1, 'year').endOf('year');
        }
    }

    setChartStartDate = (date) => {
        this.chartStartDate = date;
    }

    setChartEndDate = (date) => {
        this.chartEndDate = date;
    }

    setChartChangeDateType = (type) => {
        this.chartChangeDateType = type;
    }

    setWordFilterType = (type) => {
        this.wordFilterType = type;
    }

    setDialogFilterType = (type) => {
        this.dialogFilterType = type;
    }

    setUserSortColumn = (column) => {
        this.userSortColumn = column;
    }

    setUserSortDirection = (direction) => {
        this.userSortDirection = direction;
    }

    setNprojPage = (page) => {
        this.nprojPage = page;
    }

    setNcp2Page = (page) => {
        this.ncp2Page = page;
    }

    setMainFirmwarePage = (page) => {
        this.minFirmwarePage = page;
    }

    setSubFirmwarePage = (page) => {
        this.subFirmwarePage = page;
    }

    setNprojSortColumn = (column) => {
        this.nprojSortColumn = column;
    }

    setNprojSortDirection = (direction) => {
        this.nprojSortDirection = direction;
    }

    * getUsageStatistics(callback) {
        try {
            console.log(logPrefix, 'Start getUsageStatistics.');
            this.isUsageLoading = true;

            const response = yield this.dashboardRepository.getUsageStatistics();

            this.usageStatistics = response.data;

            console.log(logPrefix, 'Finished getUsageStatistics.');

            callback && callback();
        } catch(e) {
            this.usageStatistics = '';
            console.log(logPrefix, 'Failed getConversationList. error: ' + e);
        } finally {
            this.isUsageLoading = false;
        }
    }

    * getUsageChartStatistics(callback) {
        try {
            console.log(logPrefix, 'Start getUsageChartStatistics.');
            this.isUsageChartLoading = true;

            const startDate = this.chartStartDate.format('YYYY-MM-DD');
            const endDate = this.chartEndDate.format('YYYY-MM-DD');

            const response = yield this.dashboardRepository.getUsageChartStatistics(startDate, endDate);

            for (const usageChartStatistics of response.data) {
                let usageTime = Math.floor(usageChartStatistics.usageTime / 60);

                if ((usageChartStatistics.usageTime % 60) >= 30) {
                    usageTime = usageTime + 1;
                }

                usageChartStatistics.usageTime = usageTime;
            }
            this.usageChartStatisticsList = response.data;

            console.log(logPrefix, 'Finished getUsageChartStatistics.');

            callback && callback();
        } catch(e) {
            this.usageChartStatisticsList = [];
            console.log(logPrefix, 'Failed getUsageChartStatistics. error: ' + e);
        } finally {
            this.isUsageChartLoading = false;
        }
    }

    * getWordStatistics(callback) {
        try {
            console.log(logPrefix, 'Start getWordStatistics.');
            this.isWordLoading = true;

            const response = yield this.dashboardRepository.getWordStatistics(this.wordFilterType);

            this.wordStatisticsList = response.data;

            console.log(logPrefix, 'Finished getWordStatistics.');

            callback && callback();
        } catch(e) {
            this.wordStatisticsList = [];
            console.log(logPrefix, 'Failed getWordStatistics. error: ' + e);
        } finally {
            this.isWordLoading = false;
        }
    }

    * getDialogStatistics(limit, callback) {
        try {
            console.log(logPrefix, 'Start getDialogStatistics.');
            this.isDialogLoading = true;

            const response = yield this.dashboardRepository.getDialogStatistics(limit, this.dialogFilterType);

            if (response.data.length >= 10) {
                const list = response.data.slice(0, 9);
                const etcTotalCount = response.data.slice(9).reduce((total, d) => total + d.count, 0);
                const remainingObject = {
                    title: "기타",
                    count: etcTotalCount
                };
                list.push(remainingObject);
                list.sort((a, b) => b.count - a.count);

                this.etcDialogList = response.data.slice(9);

                this.dialogStatisticsList = list;
            } else {
                this.dialogStatisticsList = response.data;
            }

            console.log(logPrefix, 'Finished getDialogStatistics.');

            callback && callback();
        } catch(e) {
            this.dialogStatisticsList = [];
            this.etcDialogList = [];
            console.log(logPrefix, 'Failed getDialogStatistics. error: ' + e);
        } finally {
            this.isDialogLoading = false;
        }
    }

    * getDashboardUserStatistics(callback) {
        try {
            console.log(logPrefix, 'Start getDashboardUserStatistics.');
            this.userStatisticsList = [];

            this.isUserLoading = true;

            const response = yield this.dashboardRepository.getUserStatistics(this.searchId, 5, 1, this.userSortColumn, this.userSortDirection);

            this.userStatisticsListCount = response.data.totalCount;
            this.userStatisticsList = response.data.userList;

            console.log(logPrefix, 'Finished getDashboardUserStatistics.');

            callback && callback();
        } catch(e) {
            this.userStatisticsList = [];
            console.log(logPrefix, 'Failed getDashboardUserStatistics. error: ' + e);
        } finally {
            this.isUserLoading = false;
        }
    }

    * getUserStatistics(callback) {
        try {
            console.log(logPrefix, 'Start getUserStatistics.');
            this.userStatisticsList = [];

            this.isUserLoading = true;

            const response = yield this.dashboardRepository.getUserStatistics(this.searchId, this.rowsPerPage, this.page, this.userSortColumn, this.userSortDirection);

            this.userStatisticsListCount = response.data.totalCount;
            this.userStatisticsList = response.data.userList;

            console.log(logPrefix, 'Finished getUserStatistics.');

            callback && callback();
        } catch(e) {
            this.userStatisticsList = [];
            console.log(logPrefix, 'Failed getUserStatistics. error: ' + e);
        } finally {
            this.isUserLoading = false;
        }
    }

    * downloadCSV() {
        try {
            console.log(logPrefix, 'Start downloadCSV.');

            this.isCSVLoading = true;
            const startDate = this.csvSearchStartDate.format('YYYY-MM-DD');
            const endDate = this.csvSearchEndDate.format('YYYY-MM-DD');

            const result =  yield axios.get(`/api/v1/statistics/csv?userId=${this.csvSearchUserId}&startDate=${startDate}&endDate=${endDate}&title=${this.csvSearchTitle}&wordList=${this.csvSearchWordList}`, {
                headers: { 'Content-Type': 'application/vnd.ms-excel' },
                responseType: 'arraybuffer',
            });
            const file = result.data;
            const fileName = 'Pokoro_dialog.xlsx';
            fileDownload( file, fileName );

            console.log(logPrefix, 'Finished downloadCSV.');
        } catch (e) {
            console.log(logPrefix, 'Failed downloadCSV. error: ' + e);
        } finally {
            this.isCSVLoading = false;
        }
    }

    * getTitleList(callback) {
        try {
            console.log(logPrefix, 'Start getTitleList.');
            this.titleList = [];

            this.isTitleLoading = true;

            const response = yield this.dashboardRepository.getTitleList();

            this.titleList = response.data;

            console.log(logPrefix, 'Finished getTitleList.');

            callback && callback();
        } catch(e) {
            this.titleList = [];
            console.log(logPrefix, 'Failed getTitleList. error: ' + e);
        } finally {
            this.isTitleLoading = false;
        }
    }

    * getNprojList(callback) {
        try {
            console.log(logPrefix, 'Start getNprojList.');
            this.nprojListCount = 0;
            this.nprojList = [];

            this.isNprojListLoading = true;

            const response = yield this.nCodeEditRepository.getNprojList(this.nprojRowsPerPage, this.nprojPage, this.nprojSortColumn, this.nprojSortDirection);

            this.nprojListCount = response.data.totalCount;
            this.nprojList = response.data.nprojList;

            console.log(logPrefix, 'Finished getNprojList.');

            callback && callback();
        } catch(e) {
            this.nprojListCount = 0;
            this.nprojList = [];
            console.log(logPrefix, 'Failed getNprojList. error: ' + e);
        } finally {
            this.isNprojListLoading = false;
        }
    }

    * addNproj(file, description, callback, cllback2) {
        try {
            console.log(logPrefix, 'Start addNproj.');
            this.isAddNprojLoading = true;

            const param = new FormData();
            const nprojInfo = new Blob([JSON.stringify({
                title: '',
                description: description
            })], {
                type: 'application/json;charset=utf-8'
            });

            param.append('nprojInfo', nprojInfo);
            param.append('nprojFile', file);

            yield this.nCodeEditRepository.addNproj(param);

            console.log(logPrefix, 'Finished addNproj.');

            callback && callback();
        } catch(e) {
            console.log(logPrefix, 'Failed addNproj. error: ' + e);
            if (e.response.data.errorCode === 'Duplicate') {
                cllback2 && cllback2(e.response.data.message);
            }
        } finally {
            this.isAddNprojLoading = false;
        }
    }

    * updateNproj(id, description, callback) {
        try {
            console.log(logPrefix, 'Start updateNproj.');
            this.isUpdateNprojLoading = true;

            const param = new FormData();
            const nprojInfo = new Blob([JSON.stringify({
                description: description
            })], {
                type: 'application/json;charset=utf-8'
            });

            param.append('nprojInfo', nprojInfo);

            yield this.nCodeEditRepository.updateNproj(id, param);

            console.log(logPrefix, 'Finished updateNproj.');

            callback && callback();
        } catch(e) {
            console.log(logPrefix, 'Failed updateNproj. error: ' + e);
        } finally {
            this.isUpdateNprojLoading = false;
        }
    }

    * removeNproj(nprojIdList, callback) {
        try {
            console.log(logPrefix, 'Start removeNproj.');
            this.isRemoveNprojLoading = true;

            yield this.nCodeEditRepository.removeNproj(nprojIdList);

            console.log(logPrefix, 'Finished removeNproj.');

            callback && callback();
        } catch(e) {
            console.log(logPrefix, 'Failed removeNproj. error: ' + e);
        } finally {
            this.isRemoveNprojLoading = false;
        }
    }

    * downloadNproj(nprojId) {
        try {
            console.log(logPrefix, 'Start downloadNproj.');

            this.isDownNprojLoading = true;

            const response = yield this.nCodeEditRepository.getNprojDownloadUrl(nprojId);

            const link = document.createElement("a");
            link.href = response.data;
            link.click();

            console.log(logPrefix, 'Finished downloadNproj.');
        } catch (e) {
            console.log(logPrefix, 'Failed downloadNproj. error: ' + e);
        } finally {
            this.isDownNprojLoading = false;
        }
    }

    * getNcp2List(callback) {
        try {
            console.log(logPrefix, 'Start getNcp2List.');
            this.ncp2ListCount = 0;
            this.ncp2List = [];

            this.isNcp2ListLoading = true;

            const response = yield this.nCodeEditRepository.getNcp2List(this.ncp2RowsPerPage, this.ncp2Page);

            this.ncp2ListCount = response.data.totalCount;
            this.ncp2List = response.data.ncpFileList;

            console.log(logPrefix, 'Finished getNcp2List.');

            callback && callback();
        } catch(e) {
            this.ncp2ListCount = 0;
            this.ncp2List = [];
            console.log(logPrefix, 'Failed getNcp2List. error: ' + e);
        } finally {
            this.isNcp2ListLoading = false;
        }
    }

    * addNcp2(file, description, modelList, callback, cllback2) {
        try {
            console.log(logPrefix, 'Start addNcp2.');
            this.isAddNcp2Loading = true;

            const param = new FormData();
            const ncpInfo = new Blob([JSON.stringify({
                description: description,
                modelList: modelList
            })], {
                type: 'application/json;charset=utf-8'
            });

            param.append('ncpInfo', ncpInfo);
            param.append('ncpFile', file);

            yield this.nCodeEditRepository.addNcp2(param);

            console.log(logPrefix, 'Finished addNcp2.');

            callback && callback();
        } catch(e) {
            console.log(logPrefix, 'Failed addNcp2. error: ' + e);
            if (e.response.data.errorCode === 'Duplicate') {
                cllback2 && cllback2(e.response.data.message);
            }
        } finally {
            this.isAddNcp2Loading = false;
        }
    }

    * updateNcp2(id, description, callback) {
        try {
            console.log(logPrefix, 'Start updateNcp2.');
            this.isUpdateNcp2Loading = true;

            const param = new FormData();
            const ncpFileInfo = new Blob([JSON.stringify({
                description: description
            })], {
                type: 'application/json;charset=utf-8'
            });

            param.append('ncpFileInfo', ncpFileInfo);

            yield this.nCodeEditRepository.updateNcp2(id, param);

            console.log(logPrefix, 'Finished updateNcp2.');

            callback && callback();
        } catch(e) {
            console.log(logPrefix, 'Failed updateNcp2. error: ' + e);
        } finally {
            this.isUpdateNcp2Loading = false;
        }
    }

    * removeNcp2(ncp2IdList, callback) {
        try {
            console.log(logPrefix, 'Start removeNcp2.');
            this.isRemoveNcp2Loading = true;

            yield this.nCodeEditRepository.removeNcp2(ncp2IdList);

            console.log(logPrefix, 'Finished removeNcp2.');

            callback && callback();
        } catch(e) {
            console.log(logPrefix, 'Failed removeNcp2. error: ' + e);
        } finally {
            this.isRemoveNcp2Loading = false;
        }
    }

    * downloadNcp2(ncp2Id) {
        try {
            console.log(logPrefix, 'Start downloadNcp2.');

            this.isDownNcp2Loading = true;

            const response = yield this.nCodeEditRepository.getNcp2DownloadUrl(ncp2Id);

            const link = document.createElement("a");
            link.href = response.data;
            link.click();

            console.log(logPrefix, 'Finished downloadNcp2.');
        } catch (e) {
            console.log(logPrefix, 'Failed downloadNcp2. error: ' + e);
        } finally {
            this.isDownNcp2Loading = false;
        }
    }

    * getMainFirmwareList(callback) {
        try {
            console.log(logPrefix, 'Start getMainFirmwareList.');
            this.mainFirmwareListCount = 0;
            this.mainFirmwareList = [];

            this.isMainFirmwareListLoading = true;

            const response = yield this.nCodeEditRepository.getFirmwareList('Main', this.mainFirmwareRowsPerPage, this.mainFirmwarePage);

            this.mainFirmwareListCount = response.data.totalCount;
            this.mainFirmwareList = response.data.firmwareList;

            console.log(logPrefix, 'Finished getMainFirmwareList.');

            callback && callback();
        } catch(e) {
            this.mainFirmwareListCount = 0;
            this.mainFirmwareList = [];
            console.log(logPrefix, 'Failed getMainFirmwareList. error: ' + e);
        } finally {
            this.isMainFirmwareListLoading = false;
        }
    }

    * getSubFirmwareList(callback) {
        try {
            console.log(logPrefix, 'Start getSubFirmwareList.');
            this.subFirmwareListCount = 0;
            this.subFirmwareList = [];

            this.isSubFirmwareListLoading = true;

            const response = yield this.nCodeEditRepository.getFirmwareList('Sub', this.subFirmwareRowsPerPage, this.subFirmwarePage);

            this.subFirmwareListCount = response.data.totalCount;
            this.subFirmwareList = response.data.firmwareList;

            console.log(logPrefix, 'Finished getSubFirmwareList.');

            callback && callback();
        } catch(e) {
            this.subFirmwareListCount = 0;
            this.subFirmwareList = [];
            console.log(logPrefix, 'Failed getSubFirmwareList. error: ' + e);
        } finally {
            this.isSubFirmwareListLoading = false;
        }
    }

    * addFirmware(file, kind, modelList, fileType, callback) {
        try {
            console.log(logPrefix, 'Start addFirmware.');

            if (kind === 'Main') {
                this.isAddMainFirmwareLoading = true;
            } else {
                this.isAddSubFirmwareLoading = true;
            }

            const param = new FormData();
            const firmwareInfo = new Blob([JSON.stringify({
                kind: kind,
                modelList: modelList,
                fileType: fileType
            })], {
                type: 'application/json;charset=utf-8'
            });

            param.append('firmwareInfo', firmwareInfo);
            param.append('firmwareFile', file);

            yield this.nCodeEditRepository.addFirmware(param);

            console.log(logPrefix, 'Finished addFirmware.');

            callback && callback();
        } catch(e) {
            console.log(logPrefix, 'Failed addFirmware. error: ' + e);
        } finally {
            if (kind === 'Main') {
                this.isAddMainFirmwareLoading = false;
            } else {
                this.isAddSubFirmwareLoading = false;
            }
        }
    }

    * removeFirmware(firmwareFileId, firmwareFileModelId, callback) {
        try {
            console.log(logPrefix, 'Start removeFirmware.');
            this.isRemoveFirmwareLoading = true;

            yield this.nCodeEditRepository.removeFirmware(firmwareFileId, firmwareFileModelId);

            console.log(logPrefix, 'Finished removeFirmware.');

            callback && callback();
        } catch(e) {
            console.log(logPrefix, 'Failed removeFirmware. error: ' + e);
        } finally {
            this.isRemoveFirmwareLoading = false;
        }
    }

    * downloadFirmware(firmwareFileId) {
        try {
            console.log(logPrefix, 'Start downloadFirmware.');

            this.isDownFirmwareLoading = true;

            const response = yield this.nCodeEditRepository.getFirmwareDownloadUrl(firmwareFileId);

            const link = document.createElement("a");
            link.href = response.data;
            link.click();

            console.log(logPrefix, 'Finished downloadFirmware.');
        } catch (e) {
            console.log(logPrefix, 'Failed downloadFirmware. error: ' + e);
        } finally {
            this.isDownFirmwareLoading = false;
        }
    }
}
