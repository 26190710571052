import React from "react";

import { Box } from "@mui/material";

import Chart from "react-apexcharts";
import {styled} from "@mui/material/styles";
import {inject, observer} from "mobx-react";


const Root = styled(Box)(() => ({
    width:'100%',
    '& .apexcharts-toolbar, .apexcharts-tooltip-text-y-label':{
        display:'none',
    },
    '& .apexcharts-datalabels text':{
        fontWeight:300,
        filter:'none',
    },
    '& .apexcharts-legend-marker':{
        marginRight:'14px',
    },
    '@media all and (max-width: 1280px)': {
        '& .apexcharts-legend':{
            display:'none'
        }
    },
    '& .arrowBox':{
        position:'relative',
        border:'0 none',
        padding:10,
        boxShadow:'0px 1px 3px rgba(0, 0, 0, 0.10)'
    },
}));

function PieChartComponent(props) {
    const { dashboardStore } = props;

    const getLabelList = () => {
       return dashboardStore.dialogStatisticsList.map(d => d.title);
    }

    const getColorList = () => {
        let colorList = ['#FFC019', '#FF9F19', '#F94C86', '#BC7ADC', '#3EC0F0', '#5A6198', '#E76F1F', '#3B9225', '#D70000'];
        let results = [];

        for (const dialog of dashboardStore.dialogStatisticsList) {
            if (dialog.title === '기타') {
                results.push('#B7B7B7');
            } else {
                const randomIndex = Math.floor(Math.random() * colorList.length);
                const randomColor = colorList[randomIndex];
                colorList = colorList.filter((_, index) => index !== randomIndex);

                results.push(randomColor);
            }
        }

        return results;
    }

    const getCountList = () => {
        return dashboardStore.dialogStatisticsList.map(d => d.count);
    }

    const getRate = (index, list) => {
        const count = list[index].count;
        const totalCount = dashboardStore.dialogStatisticsList.reduce((total, d) => total + d.count, 0);

        return (Math.round((count / totalCount * 100) * 10) / 10);
    }

    const options = {
        chart: {
            width: 372,
            height: 372,
            type: 'donut',
            zoom: {
                enabled: false
            }
        },
        labels: getLabelList(),
        colors: getColorList(),
        responsive: [{
            breakpoint: 560,
            options: {
                chart: {
                    width: 500
                },
            }
        }],
        legend: {
            labels: {
                colors: ['#474747'],
            },
            markers: {
                width: 30,
                height: 15,
                radius: 0,
            },
            position: 'bottom',
            itemMargin: {
                horizontal: 16,
                vertical: 8
            },
            onItemClick: {
                toggleDataSeries: false
            },
            onItemHover: {
                highlightDataSeries: false
            },
        },
        stroke: {
            show: false,
        },
        dataLabels:{
            formatter: function (val, opts) {
                return [getRate(opts.seriesIndex, dashboardStore.dialogStatisticsList) +'%']
            },
            style: {
                fontSize: '0.75rem',
                colors: ['#000'],
            },
        },
        plotOptions:{
            pie:{
                donut:{
                    size: '50%',
                    labels:{
                        show:true,
                        total: {
                            showAlways: true,
                            show: true,
                            label: '총 대화수',
                            color: '#666',
                            fontSize:'1rem',
                        },
                        value: {
                            show: true,
                            color: '#333',
                            fontSize:'1.625rem',
                            fontWeight:700,
                        },
                    }
                },
            },
        },
        tooltip: {
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
                const title = dashboardStore.dialogStatisticsList[seriesIndex].title;
                if (title !== '기타') {
                    return (
                        '<div class="arrowBox">' +
                            getRate(seriesIndex, dashboardStore.dialogStatisticsList) +
                            "% - " +
                            title +
                        "</div>"
                    );
                } else {
                    let tooltipContent = '<div class="arrowBox">';
                    for (let i = 0; i < dashboardStore.etcDialogList.length; i++) {
                        tooltipContent += getRate(i, dashboardStore.etcDialogList) + "% - " + dashboardStore.etcDialogList[i].title + "<br>";
                    }
                    tooltipContent += "</div>";
                    return tooltipContent;
                }
            }
        }
    };

    return (
        <Root>
            <Chart options={options} series={getCountList()} type="donut" height={470}/>
        </Root>
    );
}

export default inject('dashboardStore')(observer(PieChartComponent));
