import React, { useState } from "react";
import { TableRow, TableHead, TableCell, TableBody, Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TableStyle, Flex } from '../Common/CommonStyle';
import { inject, observer } from "mobx-react";
import FileAddDialog from './FileAddDialog'; 

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import dayjs from "dayjs";
import {DefaultMainFileType, DefaultModelName, DefaultSubFileType} from "../../stores/DashboardStore";


const Root = styled(Box)(() => ({
    width: '100%',
}));

const TableWrap = styled(Box)(() => ({
    overflowY: 'hidden',
    overflowX: 'auto',
    '& table.MuiTable-root': {
        minWidth: 1024,
    },
}));

function FirmwareManageTableComponents(props) {
    const {dashboardStore} = props;
    const {title, kind} = props;

    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [newFile, setNewFile] = useState(null);
    const [newFileName, setNewFileName] = useState('');

    const firmwareList = kind === 'Main' ? dashboardStore.mainFirmwareList : dashboardStore.subFirmwareList;

    const getDate = (createdDatetime) => {
        return dayjs(createdDatetime).tz('UTC', true).tz('Asia/Seoul').format('YYYY.MM.DD HH:mm:ss');
    }

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    }

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
        setNewFile(null);
        setNewFileName('');
    }

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setNewFile(file);
            setNewFileName(file.name);
        }
    };

    const handleAddNewFile = () => {
        if (newFile) {
            const modelList = [DefaultModelName];
            dashboardStore.addFirmware(newFile, kind, modelList, kind === 'Main' ? DefaultMainFileType : DefaultSubFileType, () => kind === 'Main' ? dashboardStore.getMainFirmwareList() : dashboardStore.getSubFirmwareList());
            handleCloseAddDialog();
        }
    };

    const handleClickDelete = (fileId, modelId) => {
        dashboardStore.removeFirmware(fileId, modelId, () => kind === 'Main' ? dashboardStore.getMainFirmwareList() : dashboardStore.getSubFirmwareList());
    };

    const handleClickDown = (fileId) => {
        dashboardStore.downloadFirmware(fileId);
    }

    const modalContent = (
        <>
            <TextField
                margin='dense'
                id='fileName'
                label='업로드 파일명'
                type='text'
                fullWidth
                variant='standard'
                value={newFileName}
                onChange={(e) => setNewFileName(e.target.value)}
                disabled
            />
        </>
    );

    return (
        <Root>
            <Flex sx={{ justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                <Typography
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {title}
                </Typography>
                <Button 
                    onClick={handleOpenAddDialog}
                    variant="contained" 
                    color="primary" 
                    startIcon={<AddIcon />}
                    sx={{ width: '100px' }}
                >
                    추가
                </Button>
            </Flex>
            <TableWrap>
                <TableStyle>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ width: '15%' }}>Model</TableCell>
                            <TableCell align="center" sx={{ width: '15%' }}>Chipset</TableCell>
                            <TableCell align="center" sx={{ width: '10%' }}>Ver</TableCell>
                            <TableCell align="center" sx={{ width: '15%' }}>파일명</TableCell>
                            <TableCell align="center" sx={{ width: '15%' }}>추가 날짜</TableCell>
                            <TableCell align="center" sx={{ width: '10%' }}>파일 다운로드</TableCell>
                            <TableCell align="center" sx={{ width: '5%' }}>삭제</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {firmwareList.map((row, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="center">{row.modelName}</TableCell>
                                <TableCell align="center">{row.fileType}</TableCell>
                                <TableCell align="center">{row.fileVersion}</TableCell>
                                <TableCell align="center">{row.fileName}</TableCell>
                                <TableCell align="center">{getDate(row.createdDatetime)}</TableCell>
                                <TableCell align="center">
                                    <Button 
                                        variant='outlined'
                                        color="gray"
                                        sx={{ 
                                            borderColor: 'gray', 
                                            color: 'gray.dark',
                                            '&:hover': {
                                                backgroundColor: 'gray.light',
                                                borderColor: 'gray.dark'
                                            }
                                        }}
                                        onClick={() => handleClickDown(row.fileId)}
                                    >
                                        다운로드
                                    </Button>
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton aria-label="delete" onClick={() => handleClickDelete(row.fileId, row.modelId)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TableStyle>
            </TableWrap>

            <FileAddDialog
                open={openAddDialog}
                onClose={handleCloseAddDialog}
                title="펌웨어 업로드"
                content={modalContent}
                // fileInputProps={{accept: '.bin'}}
                onFileChange={handleFileInputChange}
                onAdd={handleAddNewFile}
                onCancel={handleCloseAddDialog}
            />
        </Root>
    );
}

export default inject('dashboardStore')(observer(FirmwareManageTableComponents));
