import React from "react";
import {Box, Popover, Typography, ToggleButtonGroup, ToggleButton} from "@mui/material";
import { styled } from "@mui/material/styles";
import {ButtonStyle, ButtonStyleText, DatePickerBox, Flex, FlexColumn, StyledInput} from "../../Common/CommonStyle";
import CommonCalendar from "../../Common/CommonCalendar";
import CSVExchangeDialog from "../Dialog/CSVExchangeDialog";
import ChipInputComponent from "./ChipInputComponent";
import {inject, observer} from "mobx-react";


const PopoverBox = styled(Popover)(() => ({
    '& .MuiPaper-root':{
        width:444,
        display: 'flex',
        flexDirection: 'column',
        gap:24,
        padding:'24px 16px',
        borderRadius:8,
        boxShadow:'0px 0px 8px rgba(0, 0, 0, 0.15)',
        boxSizing:'border-box',
    }
}));

const BoxStyle = styled(FlexColumn)(() => ({
    gap:4,
}));

const Label = styled(Typography)(() => ({
    fontSize:'0.75rem',
}));

const LineBox = styled(Box)(() => ({
    width:'100%',
    minHeight:46,
    display:'flex',
    alignItems:'center',
    border:'1px solid #C9C9CA',
    borderRadius:4,
    padding:'8px 15px',
    boxSizing:'border-box',
}));


const ToggleButtonGroupStyle = styled(ToggleButtonGroup)(() => ({
    width:'100%',
    gap:4,
    '& .MuiToggleButton-root':{
        height:36,
        padding:6,
        color:'#666',
        border:'1px solid #C9C9CA',
        borderRadius: 4,
        flex:1,
        // '&:hover, &:focus, &.Mui-selected , &.Mui-selected:hover':{
        //     color:'#F9CB28',
        //     border: '1px solid #FDD751',
        //     backgroundColor:'#fff',
        // },
    },
}));

const DateLineBox = styled(LineBox)(() => ({
    justifyContent:'space-around',
    fontSize:'0.875rem',
    color:'#666',
}));

const DateInput = styled(StyledInput)(() => ({
    '& .MuiInputBase-root': {
        minHeight: 'auto',
        height: 'auto',
        padding:'0!important',
        '& input': {
            fontSize:'0.875rem',
            color:'#666',
            textAlign : 'center',
            '&::placeholder': {
                color: '#666',
            }
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '0 none!important',
        },
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '0 none!important',
            }
        },
        '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '0 none!important',
            }
        },
        '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '0 none!importants',
            },
        },
    }
}));


function CSVPopover(props) {
    const { dashboardStore } = props;

    const { open, anchorEl, handleClose } = props;

    const handleChangeType = (event, type) => {
        dashboardStore.setCSVSearchType(type);
    };

    const [startAnchorEl, setStartAnchorEl] = React.useState(null);
    const [endAnchorEl, setEndAnchorEl] = React.useState(null);

    const handleClickStart = (event) => {
        if (dashboardStore.csvSearchStartDate !== '' && dashboardStore.csvSearchEndDate !== '') {
            dashboardStore.setCSVSearchEndDate('');
        }
        dashboardStore.setCSVSearchType('custom');
        setStartAnchorEl(event.currentTarget);
    };

    const handleClickEnd = (event) => {
        if (dashboardStore.csvSearchStartDate !== '' && dashboardStore.csvSearchEndDate !== '') {
            dashboardStore.setCSVSearchStartDate('');
        }
        dashboardStore.setCSVSearchType('custom');
        setEndAnchorEl(event.currentTarget);
    };

    const handleCloseStart = () => {
        setStartAnchorEl(null);
    };

    const handleCloseEnd = () => {
        setEndAnchorEl(null);
    };

    const startOpen = Boolean(startAnchorEl);
    const endOpen = Boolean(endAnchorEl);


    const [exchangeEl, setExchangeEl] = React.useState(null);
    const handleClickCSVDownload = (event) => {
        dashboardStore.downloadCSV();
        setExchangeEl(event.currentTarget);
    };

    const handleChangeUserId = (event) => {
        dashboardStore.setCSVSearchUserId(event.target.value);
    }

    const handleChangeTitle = (event) => {
        dashboardStore.setCSVSearchTitle(event.target.value);
    }

    const verifyDate = () => {
        if (dashboardStore.csvSearchStartDate !== '' && dashboardStore.csvSearchEndDate !== '') {
            if (dashboardStore.csvSearchEndDate.isBefore(dashboardStore.csvSearchStartDate)) {
                return 1;
            } else if (dashboardStore.csvSearchEndDate.diff(dashboardStore.csvSearchStartDate, 'month') >= 6) {
                return 2;
            }
            return 0;
        }
        return 0;
    }

    const verifyWordList = () => {
        const incompleteHangulRegex = /[ㄱ-ㅎㅏ-ㅣ]/;

        for (const word of dashboardStore.csvSearchWordList) {
            if (incompleteHangulRegex.test(word)) {
                return false;
            }
        }
        return true;
    }

    const verifyTitle = () => {
        if (dashboardStore.csvSearchTitle !== '') {
            return dashboardStore.titleList.includes(dashboardStore.csvSearchTitle);
        }

        return true;
    }

    const canDownload = () => {
        if (dashboardStore.csvSearchStartDate === '' || dashboardStore.csvSearchEndDate === '') {
            return false;
        }

        if (verifyDate() !== 0) {
            return false;
        }

        if (!verifyWordList()) {
            return false;
        }

        if (!verifyTitle()) {
            return false;
        }

        return true;
    }


    return (
        <PopoverBox
            id="simple-popper"
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        >
            <BoxStyle>
                <Label>계정</Label>
                <StyledInput
                    value={dashboardStore.csvSearchUserId}
                    onChange={handleChangeUserId}
                    height={'52px'}
                    placeholder={"계정 이메일 입력"}
                />
            </BoxStyle>
            <BoxStyle>
                <Label>기간</Label>
                <Flex width={'100%'}>
                    <ToggleButtonGroupStyle
                        value={dashboardStore.csvSearchType}
                        exclusive
                        onChange={handleChangeType}
                    >
                        <ToggleButton value="today" style={dashboardStore.csvSearchType === 'today' ? {color:'#F9CB28', border: '1px solid #FDD751', backgroundColor:'#fff'} : null}>오늘</ToggleButton>
                        <ToggleButton value="weekend" style={dashboardStore.csvSearchType === 'weekend' ? {color:'#F9CB28', border: '1px solid #FDD751', backgroundColor:'#fff'} : null}>1주일</ToggleButton>
                        <ToggleButton value="month" style={dashboardStore.csvSearchType === 'month' ? {color:'#F9CB28', border: '1px solid #FDD751', backgroundColor:'#fff'} : null}>1개월</ToggleButton>
                        <ToggleButton value="quarter" style={dashboardStore.csvSearchType === 'quarter' ? {color:'#F9CB28', border: '1px solid #FDD751', backgroundColor:'#fff'} : null}>3개월</ToggleButton>
                    </ToggleButtonGroupStyle>
                </Flex>
                <DateLineBox style={dashboardStore.csvSearchType === 'custom' ? {border: '1px solid #FDD751'} : null}>
                    <DateInput value={dashboardStore.csvSearchStartDate === '' ? '0000/00/00' : dashboardStore.csvSearchStartDate.format('YYYY-MM-DD')} onClick={handleClickStart} />
                    -
                    <DateInput value={dashboardStore.csvSearchEndDate === '' ? '0000/00/00' : dashboardStore.csvSearchEndDate.format('YYYY-MM-DD')} onClick={handleClickEnd}/>
                </DateLineBox>
                {dashboardStore.csvSearchType === 'custom' &&
                    <React.Fragment>
                        {verifyDate() === 0 &&
                            <Label style={{color: '#6B70FF'}}>*기간 선택은 최대 6개월 간만 지정 가능합니다.</Label>
                        }
                        {verifyDate() === 1 &&
                            <Label style={{color: '#F64860'}}><span role="img" aria-label="Smiley face">⚠️</span>시작일이 종료일 보다 더 클 수 없습니다.</Label>
                        }
                        {verifyDate() === 2 &&
                            <Label style={{color: '#F64860'}}><span role="img" aria-label="Smiley face">⚠️</span>️최대 6개월 안으로 지정해주세요.</Label>
                        }
                    </React.Fragment>
                }
                <DatePickerBox
                    open={startOpen}
                    anchorEl={startAnchorEl}
                    onClose={handleCloseStart}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    <CommonCalendar type={'start'} close={handleCloseStart} />
                </DatePickerBox>
                <DatePickerBox
                    open={endOpen}
                    anchorEl={endAnchorEl}
                    onClose={handleCloseEnd}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    <CommonCalendar type={'end'} close={handleCloseEnd} />
                </DatePickerBox>
            </BoxStyle>
            <BoxStyle>
                <Label>콘텐츠</Label>
                <StyledInput
                    value={dashboardStore.csvSearchTitle}
                    onChange={handleChangeTitle}
                    height={'52px'}
                    placeholder={"콘텐츠명 입력"}
                    error={!verifyTitle()}
                />
                {!verifyTitle() &&
                    <Label style={{color: '#F64860'}}>해당 콘텐츠는 없는 콘텐츠입니다. 다시 입력해주세요.</Label>
                }
            </BoxStyle>
            <BoxStyle>
                <Label>단어</Label>
                <LineBox style={!verifyWordList() ? {border: '1px solid #F64860'} : null}>
                    <ChipInputComponent/>
                </LineBox>
                {!verifyWordList() &&
                    <Label style={{color: '#F64860'}}>자음, 모음만 입력할 수 없습니다.</Label>
                }
            </BoxStyle>
            <ButtonStyle height={'56px'} background={'#FDD751'} borderradius={'0'} onClick={handleClickCSVDownload} disabled={!canDownload()}>
                <ButtonStyleText fontSize={'0.875rem'} fontWeight={'500'}>CSV 다운로드</ButtonStyleText>
            </ButtonStyle>
            <CSVExchangeDialog
                open={dashboardStore.isCSVLoading} anchorel={exchangeEl}
            />
        </PopoverBox>
    );
}

export default inject('dashboardStore')(observer(CSVPopover));


