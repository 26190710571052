import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch, withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";

import {withStyles} from "@material-ui/core/styles";
import axios from "axios";

import SignIn from "./views/SignIn/SignIn";
import Dashboard from "./views/Dashboard/Dashboard";
import * as store from "./stores/AuthStore";
import CommonDialog from "./views/Common/CommonDialog";

const style = () => ({
    root: {
        display: 'flex',
    }
});



class App extends React.Component {
    componentDidMount() {
        const {loadingStore} = this.props;
        const axiosRequestInterceptors = (config) => {
            const token = localStorage.getItem(store.LocalStorageTokenKey);

            if(token) {
                config.headers['X-Auth-Token'] = token;
            }

            return config;
        };

        const axiosRequestErrorHandler = (error) => {
            return Promise.reject(error);
        };

        const axiosResponseInterceptor = (response) => {
            if(response.status === 403) {
                this.props.authStore.invalidateLogin();
                loadingStore.setIsLoading(false);
            }

            return response;
        };

        const axiosResponseErrorHandler = (error) => {
            if((error.response) && (error.response.status === 403)) {
                this.props.authStore.invalidateLogin();
                loadingStore.setIsLoading(false);
            }

            return Promise.reject(error);
        };

        console.log("========== RGate App componentDidMount ==========");
        axios.interceptors.request.use(axiosRequestInterceptors, axiosRequestErrorHandler);
        axios.interceptors.response.use(axiosResponseInterceptor, axiosResponseErrorHandler);

        this.props.authStore.checkLogin();
    }

    render() {
        const { classes } = this.props;
        const { loginState } = this.props.authStore;
        const { loadingStore } = this.props;

        return (
            <div className={classes.root}>
                <Router>
                    <Route>
                        {loginState !== store.State.Authenticated &&
                            <Switch>
                                <Route exact path="/" component={SignIn} />
                                <Route exact path="/signin" component={SignIn} />
                                <Redirect to="/" />
                            </Switch>
                        }
                        {loginState === store.State.Authenticated &&
                            <React.Fragment>
                                <Switch>
                                    <Route exact path="/" component={Dashboard} />
                                    <Route exact path="/dashboard" component={Dashboard} />
                                </Switch>
                            </React.Fragment>
                        }
                    </Route>
{/*                     <Route>
                    <Switch>
                                    <Route exact path="/" component={Dashboard} />
                                    <Route exact path="/dashboard" component={Dashboard} />
                                </Switch>
                    </Route> */}
                </Router>
                <CommonDialog
                    open={loadingStore.isLoading}
                    title={"데이터를 가져오는 중..."}
                />
            </div>
        );
    }
};

export default withStyles(style)(withRouter(inject('authStore', 'loadingStore')(observer(App))));
