import React, {useEffect} from "react";
import {Box, InputAdornment, Typography} from "@mui/material";
import { styled } from "@mui/material/styles";
import {Flex, FlexColumn, PageTitle, StyledInput, ButtonStyle, PaginationStyle} from '../Common/CommonStyle';
import {ReactComponent as SearchIcon} from "../Common/Images/SearchIcon.svg";
import UserSearchTableComponent from "./UserSearchTableComponent";
import {inject, observer} from "mobx-react";

const Root = styled(Box)(() => ({
    width: "100%",
}));

const TitleBox = styled(Flex)(() => ({
    width: "100%",
    gap:16,
    marginBottom:32,
    '& .MuiTypography-root':{
        marginBottom:'0!important',
    },
    '& .MuiTypography-subtitle1':{
        color:'#333',
        fontSize:'1rem',
        fontWeight:400,
    },
    '@media all and (max-width: 768px)': {
        flexDirection:'column',
        alignItems:'flex-start',
        marginBottom: 24,
    },
}));

const Container = styled(Box)(() => ({
    backgroundColor:'#fff',
    padding:'32px 40px',
    borderRadius:8,
    boxShadow:'2px 2px 8px rgba(0, 0, 0, 0.15)',
    boxSizing:'border-box',
}));


const SearchBox = styled(Flex)(() => ({
    width:'100%',
    gap:16,
    marginBottom:24,
    '@media all and (max-width: 768px)': {
        flexDirection:'column',
        alignItems:'flex-start',
        marginBottom: 24,
    },
}));

const SearchButtonStyle = styled(ButtonStyle)(() => ({
    '&.MuiButtonBase-root': {
        '&:hover': {
            background: '#F9CB28',
            '& p': {
                color: '#333333'
            }
        },
    },
    '& .MuiTypography-root': {
        color: '#333',
        fontSize:'1rem',
        fontWeight: 500,
        letterSpacing: '0.07px',
    },
    '@media all and (max-width: 768px)': {
        width:'100%',
    },
}));

function UserSearch(props) {
    const {dashboardStore} = props;

    useEffect(() => {
        dashboardStore.getUserStatistics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeSearchId = (event) => {
        dashboardStore.setTempSearchId(event.target.value);
    }

    const handleKeyUpSearch = (event) => {
        if(event.keyCode === 13) {
            handleClickSearch();
        }
    };

    const handleClickSearch = () => {
        dashboardStore.setSearchId(dashboardStore.tempSearchId);
        dashboardStore.getUserStatistics();
    }

    const handleChangePage = (event, page) => {
        dashboardStore.setPage(page);
        dashboardStore.getUserStatistics();
    }

    return (
        <Root>
            <TitleBox>
                <PageTitle>유저검색</PageTitle>
                <Typography variant={'subtitle1'}>유저별로 검색하여 사용 추이를 알 수 있습니다.</Typography>
            </TitleBox>
            <Container>
                <SearchBox>
                    <StyledInput
                        fullWidth
                        placeholder={'계정 이메일을 입력하세요.'}
                        width={'100%'}
                        height={'56px'}
                        fontSize={'0.91rem'}
                        onChange={handleChangeSearchId}
                        onKeyUp={handleKeyUpSearch}
                        value={dashboardStore.tempSearchId}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{ marginRight: '10px' }}>
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}></StyledInput>
                    <SearchButtonStyle
                        width={'163px'}
                        height={'54px'}
                        background={'#FDD751'}
                        onClick={handleClickSearch}
                        disableRipple
                    >
                        <Typography>검색하기</Typography>
                    </SearchButtonStyle>
                </SearchBox>
            <FlexColumn gap={3}>
                <UserSearchTableComponent />
                <PaginationStyle
                    count={Math.ceil(dashboardStore.userStatisticsListCount / dashboardStore.rowsPerPage)}
                    onChange={handleChangePage}
                    page={dashboardStore.page}
                />
            </FlexColumn>
            </Container>
        </Root>
    );
}

export default inject('dashboardStore')(observer(UserSearch));

