import React from "react";
import {Box} from "@mui/material";
import { styled } from "@mui/material/styles";
import {FlexSpace, FlexColumn, PageTitle, ButtonStyle, ButtonStyleText} from '../Common/CommonStyle';
import {ReactComponent as ReportDataIcon} from "../Common/Images/ReportDataIcon.svg";
import {ReactComponent as ChevronDownIcon} from "../Common/Images/ChevronDownIcon.svg";
import MainSummaryComponent from "./MainSummaryComponent";
import MainSummaryGraphComponent from "./MainSummaryGraphComponent";
import MainDataStatisticsComponent from "./MainDataStatisticsComponent";
import MainUserSearchComponent from "./MainUserSearchComponent";
import CSVPopover from "./Component/CSVPopover";
import {inject, observer} from "mobx-react";

const Root = styled(Box)(() => ({
    width: "100%",
}));

const PageTitleWrap = styled(FlexSpace)(() => ({
    marginBottom:32,
    '@media all and (max-width: 768px)': {
        marginBottom: 24,
    },
}));


const ButtonDownStyle = styled(ButtonStyle)(() => ({
    gap:'8px',
    '& .MuiButton-endIcon':{
        marginLeft:0,
    },
}));



function DashboardMainComponent(props) {
    const {dashboardStore} = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        dashboardStore.initCSVSearch(setAnchorEl(event.currentTarget));
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <Root>
          <PageTitleWrap>
              <PageTitle mb={'0'}>대시보드</PageTitle>
              <ButtonDownStyle width={'163px'} height={'44px'} background={'#F9CB28'}
                               endIcon={<ChevronDownIcon />}
                               onClick={handleClick}
              >
                  <ReportDataIcon/><ButtonStyleText fontSize={'0.875rem'} fontWeight={'500'}>CSV 다운로드</ButtonStyleText>
              </ButtonDownStyle>

              <CSVPopover open={open} anchorEl={anchorEl} handleClose={handleClose} />

          </PageTitleWrap>
            <FlexColumn gap={3}>
                <MainSummaryComponent/>
                <MainSummaryGraphComponent/>
                <MainDataStatisticsComponent handleChangeMenu={props.handleChangeMenu}/>
                <MainUserSearchComponent handleChangeMenu={props.handleChangeMenu}/>
            </FlexColumn>
        </Root>
    );
}

export default inject('dashboardStore')(observer(DashboardMainComponent));