import { Repository } from './Repository';

export default class DashboardRepository extends Repository {
    constructor() {
        super();

        this.dashboardRequestPrefix = '/api/v1/statistics';
    }

    getUsageStatistics = () => {
        return this.getRequestPromise('get', this.dashboardRequestPrefix + `/usage`);
    };

    getUsageChartStatistics = (startDate, endDate) => {
        return this.getRequestPromise('get', this.dashboardRequestPrefix + `/usage/chart?startDate=${startDate}&endDate=${endDate}`);
    };

    getUserStatistics = (searchId, rowsPerPage, page, sortColumn, sortDirection) => {
        return this.getRequestPromise('get', this.dashboardRequestPrefix + `/user?searchId=${searchId}&rowsPerPage=${rowsPerPage}&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`);
    };

    getTitleList = () => {
        return this.getRequestPromise('get', this.dashboardRequestPrefix + `/csv/title`);
    }

    getWordStatistics = (type) => {
        return this.getRequestPromise('get', this.dashboardRequestPrefix + `/word?type=${type}`);
    }

    getDialogStatistics = (limit, type) => {
        return this.getRequestPromise('get', this.dashboardRequestPrefix + `/dialog?limit=${limit}&type=${type}`);
    }
}
