import React, {useEffect} from "react";
import {Box, Typography} from "@mui/material";
import { styled } from "@mui/material/styles";
import {Flex, FlexCenter, FlexSpace, PageTitle} from '../Common/CommonStyle';
import CommonSelect from "../Common/CommonSelect";
import PieChartComponent from "./Component/PieChartComponent";
import ProgressLinearRankingComponent from "./Component/ProgressLinearRankingComponent";
import {inject, observer} from "mobx-react";

const Root = styled(Box)(() => ({
    width: "100%",
}));

const TitleBox = styled(Flex)(() => ({
    width: "100%",
    gap:16,
    marginBottom:32,
    '& .MuiTypography-root':{
        marginBottom:'0!important',
    },
    '& .MuiTypography-subtitle1':{
        color:'#333',
        fontSize:'1rem',
        fontWeight:400,
    },
    '@media all and (max-width: 768px)': {
        flexDirection:'column',
        alignItems:'flex-start',
        marginBottom: 24,
    },
}));

const Container = styled(Box)(() => ({
    backgroundColor:'#fff',
    borderRadius:8,
    boxShadow:'2px 2px 8px rgba(0, 0, 0, 0.15)',
}));

const SubTitleWrap = styled(FlexSpace)(() => ({
    alignItems:'flex-end',
    padding:'32px 40px 24px 40px',
    borderBottom:'1px solid #F0F1F5',
    '& .MuiTypography-h5':{
        color:'#333',
        fontSize:'1.625rem',
        fontWeight:700,
    },
    '@media all and (max-width: 768px)': {
        flexDirection:'column',
        alignItems:'flex-start',
        '& .MuiFormControl-root':{
            width:'100%',
            marginTop:16,
        },
    },
}));

const Contents = styled(Box)(() => ({
    display:'grid',
    gridTemplateColumns:'2.5fr 4.2fr',
    alignItems:'center',
    padding:'32px 40px',
    boxSizing:'border-box',
    '@media all and (max-width: 1280px)': {
        width:'100%',
        display:'flex',
        flexDirection:'column',
        gap:40,
    },
}));

const EmptyBox = styled(FlexCenter)(() => ({
    width:'100%',
    height:259,
    padding:'16px 0',
    backgroundColor:'#FBFCFE',
    color:'#C3C3C3',
    fontSize:'2.5rem',
    fontWeight:700,
}));


function ConversationComparison(props) {
    const {dashboardStore} = props;

    useEffect(() => {
        dashboardStore.getDialogStatistics(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const menuList = [
        { text: '일간', value: 'daily'}, { text: '주간', value: 'weekly'}, { text: '월간', value: 'monthly'}
    ];

    const handleChangeDialogFilter = (event) => {
        dashboardStore.setDialogFilterType(event.target.value);
        dashboardStore.getDialogStatistics(0);
    };

    return (
        <Root>
            <TitleBox>
                <PageTitle>교재/자유대화 비교</PageTitle>
                <Typography variant={'subtitle1'}>대화 중에서 교재를 통한 대화와 일상정인 대화 횟수를 비교할 수 있는 통계입니다.</Typography>
            </TitleBox>
            <Container>
                <SubTitleWrap>
                    <Typography variant={'h5'}>전체</Typography>
                    <CommonSelect filter={dashboardStore.dialogFilterType} handleChangeFilter={handleChangeDialogFilter} menuList={menuList} width={'151px'}/>
                </SubTitleWrap>
                <Contents style={dashboardStore.dialogStatisticsList.length === 0 ? {display: 'flex'} : null}>
                    {dashboardStore.dialogStatisticsList.length > 0 ?
                        <React.Fragment>
                            <PieChartComponent/>
                            <ProgressLinearRankingComponent />
                        </React.Fragment>
                        :
                        <EmptyBox>집계중입니다.</EmptyBox>
                    }
                </Contents>
            </Container>
        </Root>
    );
}

export default inject('dashboardStore')(observer(ConversationComparison));

