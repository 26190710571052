import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {ButtonStyle, FlexColumn, FlexSpace, ButtonStyleText} from "../../Common/CommonStyle";
import {inject, observer} from "mobx-react";



const Root = styled(Box)(() => ({
    display:'flex',
    flexDirection:'column',
    gap:16,
}));

const BoxStyle = styled(Box)(() => ({
    height:34,
    display:'flex',
    alignItems:'center',
    padding:'8px 0',
    '@media all and (max-width: 768px)': {
        flexDirection:'column',
        alignItems:'flex-start',
        marginBottom:16,
    },
}));

const RankingBox = styled(Typography)(() => ({
    width:50,
    color:'#F9CB28',
    fontSize:'1rem',
}));


const DetailBox = styled(FlexSpace)(() => ({
    flex:1,
    '@media all and (max-width: 768px)': {
        width:'100%',
    },
}));


const TxtBox = styled(FlexColumn)(() => ({
    width:'85%',
    '& .MuiTypography-subTitle1':{
        color:'#333',
        fontSize:'0.875rem',
        fontWeight:400,
        display: '-webkit-box',
        overflow:'hidden',
        verticalAlign:'top',
        textOverflow: 'ellipsis',
        wordBreak:'break-all',
        '-webkit-box-orient':'vertical',
        '-webkit-line-clamp':'1',
    },

}));

const BarBox = styled(Box)(() => ({
    position:'relative',
    width:'100%',
    height:8,
    borderRadius:2,
    backgroundColor:'#F0F1F5',
}));

const ProgressBar = styled('span')(() => ({
    position:'absolute',
    top:0,
    left:0,
    height:8,
    borderRadius:'0 2px 2px 0',
    overflow:'hidden',
}));


const NumBox = styled(Box)(() => ({
    color:'#333',
    fontSize:'1rem',
    fontWeight:700,
}));


const OutlinedButtonStyle = styled(ButtonStyle)(() => ({
    width:126,
    margin:'0 auto',
}));



function ProgressLinearComponent(props) {
    const { dashboardStore } = props;

    let colorList = ['#FFC019', '#FF9F19', '#F94C86', '#BC7ADC', '#3EC0F0', '#5A6198', '#E76F1F', '#3B9225', '#D70000'];
    const getColor = () => {
        const randomIndex = Math.floor(Math.random() * colorList.length);
        const randomColor = colorList[randomIndex];

        colorList = colorList.filter((_, index) => index !== randomIndex);

        return randomColor;
    }

    const getMaxValue = () => {
        return Math.max(...(dashboardStore.dialogStatisticsList.map(d => d.count)));
    }

    const getProgressValue = (count) => {
        const maxValue = getMaxValue();
        return count / maxValue * 100;
    }

    const handleClickDetail = () => {
        props.handleChangeMenu(2);
    }

    return (
                <Root>
                    <Box>
                    {dashboardStore.dialogStatisticsList.map((list, i) => (
                        <BoxStyle key={i}>
                            <RankingBox
                                style={{
                                    color: (i + 1) < 4 ? '#333' : '#666',
                                    fontWeight: (i + 1) < 4 ? 600 : 400,
                                }}

                            >{(i + 1)}위</RankingBox>
                            <DetailBox>
                                <TxtBox>
                                    <Typography variant={'subTitle1'}>{list.title}</Typography>
                                    <BarBox>
                                        <ProgressBar
                                            style={{
                                                width: getProgressValue(list.count) + '%' ,
                                                backgroundColor:getColor(),
                                            }}
                                        />
                                    </BarBox>
                                </TxtBox>
                                <NumBox>{list.count}</NumBox>
                            </DetailBox>
                        </BoxStyle>
                    ))}
                    </Box>
                    <OutlinedButtonStyle width={'126px'} height={'44px'} border={'#C9C9CA'}>
                        <ButtonStyleText onClick={handleClickDetail}>자세히 보기</ButtonStyleText>
                    </OutlinedButtonStyle>
                </Root>
    );
}

export default inject('dashboardStore')(observer(ProgressLinearComponent));


