import { Repository } from './Repository';

export default class NcodeEditRepository extends Repository {
    constructor() {
        super();

        this.nCodeEditRequestPrefix = '/api/v1/file';
    }

    getNprojList = (rowsPerPage, page, sortColumn, sortDirection) => {
        return this.getRequestPromise('get', this.nCodeEditRequestPrefix + `/nproj?rowsPerPage=${rowsPerPage}&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`);
    };

    addNproj = (param) => {
        return this.getRequestPromise('post', this.nCodeEditRequestPrefix + `/nproj`, param);
    };

    updateNproj = (nprojId, param) => {
        return this.getRequestPromise('put', this.nCodeEditRequestPrefix + `/nproj/${nprojId}`, param);
    };

    removeNproj = (nprojIdList) => {
        return this.getRequestPromise('delete', this.nCodeEditRequestPrefix + `/nproj`, nprojIdList);
    };

    getNprojDownloadUrl = (nprojId) => {
        return this.getRequestPromise('get', this.nCodeEditRequestPrefix + `/nproj/${nprojId}/download`);
    }

    getNcp2List = (rowsPerPage, page) => {
        return this.getRequestPromise('get', this.nCodeEditRequestPrefix + `/ncp?rowsPerPage=${rowsPerPage}&page=${page}`);
    };

    addNcp2 = (param) => {
        return this.getRequestPromise('post', this.nCodeEditRequestPrefix + `/ncp`, param);
    };

    updateNcp2 = (ncpFileId, param) => {
        return this.getRequestPromise('put', this.nCodeEditRequestPrefix + `/ncp/${ncpFileId}`, param);
    };

    removeNcp2 = (ncp2IdList) => {
        return this.getRequestPromise('delete', this.nCodeEditRequestPrefix + `/ncp`, ncp2IdList);
    };

    getNcp2DownloadUrl = (ncp2Id) => {
        return this.getRequestPromise('get', this.nCodeEditRequestPrefix + `/ncp/${ncp2Id}/download`);
    }

    getFirmwareList = (kind, rowsPerPage, page) => {
        return this.getRequestPromise('get', this.nCodeEditRequestPrefix + `/firmware?kind=${kind}&rowsPerPage=${rowsPerPage}&page=${page}`);
    };

    addFirmware = (param) => {
        return this.getRequestPromise('post', this.nCodeEditRequestPrefix + `/firmware`, param);
    };

    removeFirmware = (firmwareFileId, firmwareFileModelId) => {
        return this.getRequestPromise('delete', this.nCodeEditRequestPrefix + `/firmware/${firmwareFileId}/model/${firmwareFileModelId}`);
    };

    getFirmwareDownloadUrl = (firmwareFileId) => {
        return this.getRequestPromise('get', this.nCodeEditRequestPrefix + `/firmware/${firmwareFileId}/download`);
    }
}
