import React, {useEffect} from "react";
import {Box, CardHeader, CardContent} from "@mui/material";
import { styled } from "@mui/material/styles";
import {FlexSpace, CardStyle, FlexCenter} from '../Common/CommonStyle';
import CommonSelect from "../Common/CommonSelect";
import RankingComponent from "./Component/RankingComponent";
import ProgressLinearComponent from "./Component/ProgressLinearComponent";
import {inject, observer} from "mobx-react";


const Root = styled(Box)(() => ({
    width: '100%',
    display:'grid',
    gridTemplateColumns: '2fr 3fr',
    gap:24,
    '@media all and (max-width: 1280px)': {
        display:'flex',
        flexDirection:'column',
    },
}));

const CardTitleBox = styled(FlexSpace)(() => ({
    alignItems:'flex-end',
    padding:32,
    borderBottom:'1px solid #F0F1F5',
    '& .MuiCardHeader-root':{
        padding:0,
        border:'0 none',
        gap:4,
    },
    '@media all and (max-width: 768px) ': {
        flexDirection:'column',
        alignItems:'flex-start',
        gap:16,
    },
}));


const RightBox = styled(CardContent)(() => ({
    display:'flex',
    flexDirection:'column',
    gap:8,
    padding:'32px 40px !important',
}));


const LeftBox = styled(CardContent)(() => ({
    display:'flex',
    flexDirection:'column',
    padding:'32px 40px!important',
}));


const EmptyBox = styled(FlexCenter)(() => ({
    width:'100%',
    height:259,
    padding:'16px 0',
    backgroundColor:'#FBFCFE',
    color:'#C3C3C3',
    fontSize:'2.5rem',
    fontWeight:700,
}));

function MainDataStatisticsComponent(props) {
    const {dashboardStore} = props;

    useEffect(() => {
        dashboardStore.getWordStatistics();
        dashboardStore.getDialogStatistics(5);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeWordFilter = (event) => {
        dashboardStore.setWordFilterType(event.target.value);
        dashboardStore.getWordStatistics();
    };

    const handleChangeDialogFilter = (event) => {
        dashboardStore.setDialogFilterType(event.target.value);
        dashboardStore.getDialogStatistics(5);
    };

    const menuList = [
        { text: '일간', value: 'daily'}, { text: '주간', value: 'weekly'}, { text: '월간', value: 'monthly'}
    ];

    return (
        <Root>
            <CardStyle>
                <CardTitleBox>
                    <CardHeader title={'가장 많이 사용한 단어'} subheader={'가장 많이 사용한 단어를 순위별로 집계한 통계입니다.'}/>
                    <CommonSelect filter={dashboardStore.wordFilterType} handleChangeFilter={handleChangeWordFilter} menuList={menuList} width={'93px'}/>
                </CardTitleBox>
                <RightBox>
                    {dashboardStore.wordStatisticsList.length > 0 ?
                        <RankingComponent />
                        :
                        <EmptyBox>집계중입니다.</EmptyBox>
                    }
                </RightBox>
            </CardStyle>
            <CardStyle>
                <CardTitleBox>
                    <CardHeader title={'교재 / 자유 대화 비교'} subheader={'대화 중에서 교재를 통한 대화와 자유 대화 횟수를 비교할 수 있는 통계입니다.'}/>
                    <CommonSelect filter={dashboardStore.dialogFilterType} handleChangeFilter={handleChangeDialogFilter} menuList={menuList} width={'93px'}/>
                </CardTitleBox>
                <LeftBox>
                    {dashboardStore.dialogStatisticsList.length > 0 ?
                        <ProgressLinearComponent handleChangeMenu={props.handleChangeMenu}/>
                        :
                        <EmptyBox>집계중입니다.</EmptyBox>
                    }

                </LeftBox>
            </CardStyle>
        </Root>
    );
}

export default inject('dashboardStore')(observer(MainDataStatisticsComponent));