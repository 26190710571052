import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import { styled } from "@mui/material/styles";
import {Flex, FlexColumn, PageTitle, PaginationStyle} from '../Common/CommonStyle';
import {inject, observer} from "mobx-react";
import NprojManageTableComponents from "./NprojManageTableComponents";

const Root = styled(Box)(() => ({
    width: "100%",
}));

const TitleBox = styled(Flex)(() => ({
    width: "100%",
    gap:16,
    marginBottom:32,
    '& .MuiTypography-root':{
        marginBottom:'0!important',
    },
    '& .MuiTypography-subtitle1':{
        color:'#333',
        fontSize:'1rem',
        fontWeight:400,
    },
    '@media all and (max-width: 768px)': {
        flexDirection:'column',
        alignItems:'flex-start',
        marginBottom: 24,
    },
}));

const Container = styled(Box)(() => ({
    backgroundColor:'#fff',
    padding:'32px 40px',
    borderRadius:8,
    boxShadow:'2px 2px 8px rgba(0, 0, 0, 0.15)',
    boxSizing:'border-box',
}));


function NprojManage(props) {
    const {dashboardStore} = props;

    const [checkedIdList, setCheckedIdList] = useState([]);

    useEffect(() => {
        dashboardStore.getNprojList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePage = (event, page) => {
        setCheckedIdList([]);
        dashboardStore.setNprojPage(page);
        dashboardStore.getNprojList();
    }

    return (
        <Root>
            <TitleBox>
                <PageTitle>Nproj 관리</PageTitle>
            </TitleBox>
            <Container>
            <FlexColumn gap={3}>
                <NprojManageTableComponents checkedIdList={checkedIdList} setCheckedIdList={setCheckedIdList}/>
                <PaginationStyle
                    count={Math.ceil(dashboardStore.nprojListCount / dashboardStore.nprojRowsPerPage)}
                    onChange={handleChangePage}
                    page={dashboardStore.nprojPage}
                />
            </FlexColumn>
            </Container>
        </Root>
    );
}

export default inject('dashboardStore')(observer(NprojManage));

