import React from "react";

import {
  DialogBox,
  DialogTitleText,
  DialogLoadingBox,
  DialogLoader,
} from "./CommonStyle";

export default function CommonDialog(props) {
  const {
    open,
    title,
  } = props;

  return (
    <DialogBox open={open}>
      <DialogTitleText>{title}</DialogTitleText>
      <div>
        <DialogLoadingBox>
          <DialogLoader style={{marginBottom:0}}>
            <svg className="spinner" viewBox="0 0 48 48">
              <circle className="path" cx="24" cy="24" r="21.5" fill="none" strokeWidth="5"></circle>
            </svg>
          </DialogLoader>
        </DialogLoadingBox>
      </div>
    </DialogBox>
  );
}
