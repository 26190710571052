import React from 'react';
import {Chip} from '@mui/material';
import {styled} from "@mui/material/styles";
import {Flex, StyledInput} from "../../Common/CommonStyle";
import {ReactComponent as CancelIcon} from "../../Common/Images/CancelIcon.svg";
import {inject, observer} from "mobx-react";


const Root = styled(Flex)(() => ({
    flexWrap:'wrap',
    gap:4,
}));


const ChipStyle = styled(Chip)(({ theme }) => ({
    minWidth: 48,
    height: 28,
    padding: '4px 10px',
    backgroundColor: '#FFFAE7',
    border:'1px solid #F9CB28',
    borderRadius: 25,
    lineHeight: 'initial',
    boxSizing: 'border-box',
    '& .MuiChip-label': {
        padding: 0,
        color: '#666666',
        fontSize: '0.875rem',
        fontWeight: 400,
        overflow:'visible',
    },
    '& .MuiChip-deleteIcon':{
        margin:'0 0 0 4px',
        '& svg path':{
            fill:'#333',
        }
    },
    '&:hover':{
        backgroundColor: '#FFFAE7',
    },
}));

export const InputStyle = styled(StyledInput)(({ height, fontSize}) => ({
    '&.MuiFormControl-root': {
        width: 'auto',
    },
    '& .MuiInputBase-root': {
        minHeight: 28,
        height: 28,
        padding: '0 !important',
        '& .MuiOutlinedInput-notchedOutline': {
            border: '0 none',
        },
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '0 none',
            }
        },
        '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '0 none',
            }
        },
        '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '0 none',
            },
        },

    }
}));


function ChipInput(props) {
    const {dashboardStore} = props;

    const handleKeyUp = (event) => {
        if ((event.keyCode === 13 || event.keyCode === 32) && dashboardStore.csvSearchWordInput.trim() !== '') {
            const newWordList = [...dashboardStore.csvSearchWordList];
            newWordList.push(dashboardStore.csvSearchWordInput.trim());

            dashboardStore.setCSVSearchWordList(newWordList);
            dashboardStore.setCSVSearchWordInput('');
        }
    };

    const handleDelete = (event, index) => {
        const newWordList = [...dashboardStore.csvSearchWordList];
        newWordList.splice(index, 1);

        dashboardStore.setCSVSearchWordList(newWordList);
    };

    const handleChange = (event) => {
        dashboardStore.setCSVSearchWordInput(event.target.value);
    };

    return (
        <Root>
            {dashboardStore.csvSearchWordList.map((word, i) => (
                    <ChipStyle
                        key={i}
                        label={word}
                        onDelete={(event) => handleDelete(event, i)}
                        deleteIcon={<CancelIcon />}
                        disableRipple
                    />
                ))}
            <InputStyle
                disabled={dashboardStore.csvSearchWordList.length === 3}
                value={dashboardStore.csvSearchWordInput}
                onChange={handleChange}
                onKeyUp={handleKeyUp}
            />
        </Root>
    );
}

export default inject('dashboardStore')(observer(ChipInput));