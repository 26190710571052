import React, {useEffect} from "react";
import {Box, CardContent, CardHeader, InputAdornment, Typography} from "@mui/material";
import { styled } from "@mui/material/styles";
import {CardStyle, Flex, FlexSpace, StyledInput, ButtonStyle} from '../Common/CommonStyle';
import {ReactComponent as SearchIcon} from "../Common/Images/SearchIcon.svg";
import MainUserSearchTableComponent from "./MainUserSearchTableComponent";
import {inject, observer} from "mobx-react";


const Root = styled(Box)(() => ({
    width: "100%",

}));
const CardTitleBox = styled(FlexSpace)(() => ({
    padding:'32px 40px 24px 40px',
    borderBottom:'1px solid #F0F1F5',
    '& .MuiCardHeader-root':{
        gap:4,
        padding:0,
        border:'0 none',
    },
    '@media all and (max-width: 1280px)': {
        flexDirection:'column',
        alignItems:'flex-start',
    },
    '@media all and (max-width: 1280px) and (min-width: 1023px)': {
        padding:'32px 32px 24px 32px',
    },
    '@media all and (max-width: 1024px)': {
        padding:'24px 24px 16px 24px',
    },
    '@media all and (max-width: 768px)': {
        gap:16,
    },
}));
const CardContentWrap = styled(CardContent)(() => ({
    '&.MuiCardContent-root': {
        padding:'32px 40px',
        '@media all and (max-width: 1280px) and (min-width: 1023px)': {
            padding:32,
        },
        '@media all and (max-width: 1024px)': {
            padding:24,
        },
    }
}));
const SearchBox = styled(Flex)(() => ({
    gap:8,
    '@media all and (max-width: 1280px)': {
        '& .MuiFormControl-root':{
            width:'100%!important',
        },
    },
    '@media all and (max-width: 768px)': {
        width:'100%',
        flexDirection:'column',
        alignItems:'flex-start',
    },
}));
const SearchButtonStyle = styled(ButtonStyle)(() => ({
    '&.MuiButtonBase-root': {
        '&:hover': {
            background: '#F9CB28',
            '& p': {
                color: '#333333'
            }
        },
    },
    '& .MuiTypography-root': {
        color: '#333',
        fontSize:'1rem',
        fontWeight: 500,
        letterSpacing: '0.07px',
    },
    '@media all and (max-width: 768px)': {
        width:'100%',
    },
}));

function MainUserSearchComponent(props) {
    const {dashboardStore} = props;

    useEffect(() => {
        dashboardStore.getDashboardUserStatistics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeSearchId = (event) => {
        dashboardStore.setTempSearchId(event.target.value);
    }

    const handleKeyUpSearch = (event) => {
        if(event.keyCode === 13) {
            handleClickSearch();
        }
    };

    const handleClickSearch = () => {
        dashboardStore.setSearchId(dashboardStore.tempSearchId);
        dashboardStore.getDashboardUserStatistics();
    }

    return (
        <Root>
            <CardStyle>
                <CardTitleBox>
                    <CardHeader title={'유저 검색'} subheader={'유저별로 검색하여 사용 추이를 알 수 있습니다.'}/>
                    <SearchBox>
                        <StyledInput
                            variant="outlined"
                            fullWidth
                            placeholder={'계정 이메일을 입력하세요.'}
                            width={'518px'}
                            height={'44px'}
                            fontSize={'0.91rem'}
                            onChange={handleChangeSearchId}
                            onKeyUp={handleKeyUpSearch}
                            value={dashboardStore.tempSearchId}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" sx={{ marginRight: '10px' }}>
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}></StyledInput>
                        <SearchButtonStyle
                            width={'104px'}
                            height={'44px'}
                            background={'#FDD751'}
                            onClick={handleClickSearch}
                            disableRipple
                        >
                            <Typography>검색하기</Typography>
                        </SearchButtonStyle>
                    </SearchBox>
                </CardTitleBox>
                <CardContentWrap>
                    <MainUserSearchTableComponent handleChangeMenu={props.handleChangeMenu}/>
                </CardContentWrap>
            </CardStyle>
        </Root>
    );
}

export default inject('dashboardStore')(observer(MainUserSearchComponent));


