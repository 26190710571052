import React, {useEffect} from "react";

import {Box} from "@mui/material";

import Chart from "react-apexcharts";
import {styled} from "@mui/material/styles";
import {inject, observer} from "mobx-react";


const Root = styled(Box)(() => ({
    width:'100%',
    '& .apexcharts-toolbar, .apexcharts-tooltip-text-y-label':{
        display:'none',
    },
    '& .apexcharts-tooltip':{
        border:'0 none!important',
        overflow: 'visible !important',
        whiteSpace: 'normal !important',
    },
    '& .arrowBox':{
        position:'relative',
        border:'0 none',
        padding:10,
        boxShadow:'0px 1px 3px rgba(0, 0, 0, 0.10)',
        '& .label':{
            color:'#666',
            fontSize:'0.75rem',
            letterSpacing:-0.43,
            marginBottom:2,
        },
        '& .number':{
            display:'flex',
            alignItems:'center',
            color:'#333',
            fontSize:'1.25rem',
            fontWeight:700,
        },
        '& .marker':{
            display:'block',
            width:10,
            height:10,
            backgroundColor:'#FDD751',
            borderRadius:10,
            marginRight:8,
        }
    },
}));

function LineChartComponent(props) {
    const {dashboardStore} = props;

    useEffect(() => {
        dashboardStore.getUsageChartStatistics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataList = () => {
        if (props.type === 0) {
            return [{data: dashboardStore.usageChartStatisticsList.map(s => s.totalUserCount)}]
        } else if (props.type === 1) {
            return [{data: dashboardStore.usageChartStatisticsList.map(s => s.newUserCount)}]
        } else if (props.type === 2) {
            return [{data: dashboardStore.usageChartStatisticsList.map(s => s.activatedUserCount)}]
        } else if (props.type === 3) {
            return [{data: dashboardStore.usageChartStatisticsList.map(s => s.dialogCount)}]
        } else {
            return [{data: dashboardStore.usageChartStatisticsList.map(s => s.usageTime)}]
        }
    }

    const getDigitCount = (num) => {
        return Math.floor(Math.log10(num)) + 1;
    }

    const adjustValue = (num, digitCount) => {
        const base = Math.pow(10, digitCount - 1);
        return Math.ceil(num / base) * base;
    }

    const getMaxY = (maxNum) => {
        if (maxNum < 10) {
            return 10;
        }
        if (Math.log10(maxNum) % 1 === 0) {
            return maxNum * 1.1;
        } else {
            const digitCount = getDigitCount(maxNum);
            const maxY = adjustValue(maxNum, digitCount + 1);

            if ((maxY / 2) === maxNum) {
                return (maxY / 2) + (maxY * 0.1);
            } else if ((maxY / 2) > maxNum) {
                return maxY / 2;
            } else {
                return maxY;
            }
        }

    }

    const options = {
        xaxis: {
            categories: dashboardStore.usageChartStatisticsList.map(s => s.statisticsDate),
            labels: {
                style: {
                    colors: "#666666",
                    fontSize:"0.813rem",
                }
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            min: 0,
            max: getMaxY(Math.max(...getDataList()[0].data)),
            // stepSize: 10,
            axisBorder: {
                show: true,
                color: '#F3F4F6',
            },
            labels: {
                style: {
                    colors: "#666666",
                    fontSize:"0.813rem",
                },
                precision: 1,
                formatter: function(value) {
                    if (getMaxY(Math.max(...getDataList()[0].data)) > 1000) {
                        return (value / 1000) + 'K';
                    }
                    // if (getDataList()[0].data.some(data => data > 1000)) {
                    //     return (value / 1000).toFixed(1) + 'K';
                    // }
                    // if (value >= 1000) {
                    //     return (value / 1000).toFixed(1) + 'K';
                    // }
                    return value;
                }
            },
        },
        chart: {
            height: 380,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        colors: ['#FDD751'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 3,
            dashArray: 0,
        },
        grid: {
            row: {
                colors: ['transparent', 'transparent'],
            },
            yaxis: {
                min: 0,
                max: 240000,
            },
        },
        tooltip: {
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
                return (
                    '<div class="arrowBox">' +
                    '<span class="label">' +
                    dashboardStore.usageChartStatisticsList[w.globals.labels[dataPointIndex] - 1].tooltipDate +
                    "</span>" +
                    '<div class="number"><span class="marker"></span>' +
                        series[seriesIndex][dataPointIndex].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                        (props.type === 4 ? '분' : '') +
                    "</div>"+
                    "</div>"
                );
            }
        }
    };

    return (
        <Root>
            <Chart options={options} series={getDataList()} type="line" height={380}/>
        </Root>
    );
}

export default inject('dashboardStore')(observer(LineChartComponent));
