import React, {useEffect} from "react";
import {Box} from "@mui/material";
import { styled } from "@mui/material/styles";
import {Flex, FlexColumn, PageTitle, PaginationStyle} from '../Common/CommonStyle';
import {inject, observer} from "mobx-react";
import FirmwareManageTableComponents from "./FirmwareManageTableComponents";

const Root = styled(Box)(() => ({
    width: "100%",
})); 

const TitleBox = styled(Flex)(() => ({
    width: "100%",
    gap:16,
    marginBottom:32,
    '& .MuiTypography-root':{
        marginBottom:'0!important',
    },
    '& .MuiTypography-subtitle1':{
        color:'#333',
        fontSize:'1rem',
        fontWeight:400,
    },
    '@media all and (max-width: 768px)': {
        flexDirection:'column',
        alignItems:'flex-start',
        marginBottom: 24,
    },
}));

const Container = styled(Box)(() => ({
    backgroundColor:'#fff',
    padding:'32px 40px',
    borderRadius:8,
    boxShadow:'2px 2px 8px rgba(0, 0, 0, 0.15)',
    boxSizing:'border-box',
}));

function FirmwareManage(props) {
    const {dashboardStore} = props;

    useEffect(() => {
        dashboardStore.getMainFirmwareList();
        dashboardStore.getSubFirmwareList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const handleChangeMainPage = (event, page) => {
        dashboardStore.setMainFirmwarePage(page);
        dashboardStore.getMainFirmwareList();
    }
    const handleChangeSubPage = (event, page) => {
        dashboardStore.setSubFirmwarePage(page);
        dashboardStore.getSubFirmwareList();
    }

    return (
        <Root>
            <TitleBox>
                <PageTitle>Firmware 관리</PageTitle>
            </TitleBox>
            <Container>
                <FlexColumn gap={3}>
                    <FirmwareManageTableComponents 
                        title="메인칩" 
                        kind={'Main'}
                    />
                    <PaginationStyle
                        count={Math.ceil(dashboardStore.mainFirmwareListCount / dashboardStore.mainFirmwareRowsPerPage)}
                        onChange={handleChangeMainPage}
                        page={dashboardStore.mainFirmwarePage}
                    />
                    <FirmwareManageTableComponents 
                        title="통신칩"
                        kind={'Sub'}
                    />

                    <PaginationStyle
                        count={Math.ceil(dashboardStore.subFirmwareListCount / dashboardStore.subFirmwareRowsPerPage)}
                        onChange={handleChangeSubPage}
                        page={dashboardStore.subFirmwarePage}
                    />
                </FlexColumn>
            </Container>
        </Root>
    );
}

export default inject('dashboardStore')(observer(FirmwareManage));
