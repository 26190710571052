import React from "react";
import {DateCalendarBox } from "./CommonStyle";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {inject, observer} from "mobx-react";
import dayjs from "dayjs";

function CommonCalendar(props) {
    const {dashboardStore} = props;
    const {type} = props;

    const startDate = dashboardStore.csvSearchStartDate;
    const endDate = dashboardStore.csvSearchEndDate;
    const date = type === 'start' ? startDate : endDate;

     const getMinDate = () => {
         if (type === 'start') {
             if (endDate !== '') {
                 return endDate.subtract(6, 'month');
             }
         } else {
             if (startDate !== '') {
                 return startDate;
             }
         }
         return null;
     }

     const getMaxDate = () => {
         if (type === 'start') {
             if (endDate !== '') {
                 return endDate;
             }
         } else {
             if (startDate !== '') {
                 return startDate.add(6, 'month').isBefore(dayjs()) ? startDate.add(6, 'month') : dayjs();
             }
         }
         return dayjs();
     }

    const handleChangeValue = (newValue) => {
        if (type === 'start') {
            dashboardStore.setCSVSearchStartDate(newValue);
        } else {
            dashboardStore.setCSVSearchEndDate(newValue);
        }

        props.close();
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DateCalendarBox
                    format="YYYY/MM/DD"
                    onChange={(newValue) => handleChangeValue(newValue)}
                    value={date === '' ? null : date}
                    minDate={getMinDate()}
                    maxDate={getMaxDate()}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}

export default inject('dashboardStore')(observer(CommonCalendar));


