import React from "react";
import {
    DialogBox,
    DialogTitleText,
} from "../../Common/CommonStyle";
import {styled} from "@mui/material/styles";
import {ReactComponent as ListBigIcon} from "../../Common/Images/ListBigIcon.svg";
import {Typography} from "@mui/material";

const DialogBoxStyle = styled(DialogBox)(() => ({
    "& .MuiPaper-root": {
        width: 400,
        height:450,
        alignItems:'center',
        padding: 24,
        borderRadius: 0,
        gap:21,
    },
}));
const DialogTitleTextStyle = styled(DialogTitleText)(() => ({
    "&.MuiTypography-root": {
        fontSize: "1.25rem",
    },
}));
const BodyTxtStyle = styled(Typography)(() => ({
    "&.MuiTypography-root": {
        color:'#333',
        fontSize: "1rem",
        textAlign:'center',
        lineHeight:'22.4px',
        padding:'0 24px',
        '& > span':{
            color:'#F64860',
        },
    },
}));

export default function CSVExchangeDialog(props) {
    const {
        open,
        anchorel,
        handleClose,
    } = props;



    return (
        <DialogBoxStyle
                   open={open}
                   anchorel={anchorel}
                   onClose={handleClose}
                   anchororigin={{
                       vertical: "bottom",
                       horizontal: "right",
                   }}
                   transformorigin={{
                       vertical: "top",
                       horizontal: "right",
                   }}
        >
            <ListBigIcon/>
            <DialogTitleTextStyle>CSV 파일로 변환 중입니다</DialogTitleTextStyle>
            <BodyTxtStyle>
                데이터 양에 따라 <span>최대 10분 정도</span> 소요되오니 CSV 파일로 변환 후 다운로드가 완료될 때까지 <span>절대 종료하지 말아주세요.</span>
            </BodyTxtStyle>
        </DialogBoxStyle>
    );
}
