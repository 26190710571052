import { createTheme } from "@mui/material/styles";

export default function configureTheme() {
    return createTheme({
        drawerWidth: 240,
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                        '&:hover': {
                            boxShadow: 'none'
                        }
                    }
                }
            }
        },
        palette: {
            primary: {
                main: '#FDD751',
                dark: '#F9CB28'
            },
            error: {
                main: '#F64860'
            },
            warning: {
                main: '#ff993c'
            },
            info: {
                main:'#868aff',
                contrastText: '#fff',
            },
            gray: {
                main: '#909195',
                dark: '#666666',
                light: '#f0f1f5',
                contrastText: '#fff',
            }
        }
    });
};
