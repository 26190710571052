import React, {useEffect} from "react";
import {Box, CardContent, CardHeader, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {CardStyle, Flex, FlexColumn,} from '../Common/CommonStyle';
import {ReactComponent as ArrowDownIcon} from "../Common/Images/ArrowDownFillRedIcon.svg";
import {ReactComponent as ArrowUpIcon} from "../Common/Images/ArrowUpFillBlueIcon.svg";
import {ReactComponent as ArrowMinusIcon} from "../Common/Images/ArrowMinusIcon.svg";
import {inject, observer} from "mobx-react";


const Root = styled(Box)(() => ({
    width: '100%',
    display:'flex',
    flexWrap:'wrap',
    gap:24,
    '& .MuiCard-root':{
        width: 'max-content',
        flex:1,
    },
    '@media all and (max-width: 1280px) and (min-width: 769px)': {
        '& .MuiCard-root':{
            minWidth:218,
        },
    },
    '@media all and (max-width: 768px)': {
        '& .MuiCard-root':{
            minWidth:156,
        },
    },
}));


const TotalStyle = styled(Typography)(({ theme }) => ({
    '&.MuiTypography-root': {
        color:'#111827',
        fontSize: '1.625rem',
        fontWeight: 700,
        lineHeight:'36.4px',
        marginBottom:24,
    }
}));
const SummaryTitle = styled(Typography)(({ theme }) => ({
    '&.MuiTypography-root': {
        color:'#666666',
        fontSize:  '0.875rem',
        fontWeight:  400,
    }
}));
const SummaryContents = styled(Flex)(({ theme }) => ({
    alignItems:'center',
    gap:8,
    '& .MuiTypography-root': {
        display:'flex',
        alignItems:'center',
        gap:4,
        fontSize:'1rem',
        fontWeight:700,
        lineHeight:'22.4px',
        color:'#6B70FF',
    },
}));
const Gutter = styled(Box)(({ theme }) => ({
    width:1,
    height:14,
    backgroundColor:'#C3C3C3',
}));

function MainSummaryComponent(props) {
    const {dashboardStore} = props;

    useEffect(() => {
        dashboardStore.getUsageStatistics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const data = dashboardStore.usageStatistics;

    const getNumber = (num) => {
        const absNum = Math.abs(num);

        if (absNum >= 10000) {
            const result = Math.round(absNum / 1000) / 10;
            return num < 0 ? '-' + result + '만' : result + '만';
        } else if (absNum >= 1000) {
            const result = Math.round(absNum / 100) / 10;
            return num < 0 ? '-' + result + '천' : result + '천';
        } else {
            return num.toString();
        }
    }

    const getRate = (yesterday, today) => {
        if (yesterday === 0 || today === 0) {
            return 0;
        } else {
            return (((today - yesterday) / yesterday) * 100).toFixed(1);
        }
    }

    const getTime = (seconds) => {
        let isMinus = false;

        if (seconds < 0) {
            seconds = seconds * -1;
            isMinus = true;
        }

        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds % 3600) / 60);
        let remainderSeconds = seconds % 60;

        if (remainderSeconds >= 30) {
            minutes += 1;
        }

        if (minutes >= 60) {
            minutes -= 60;
            hours += 1;
        }

        return  (isMinus && '-') + hours + "시간 " + minutes + "분";
    }

    return (
        <Root>
            {dashboardStore.usageStatistics !== '' ?
                <React.Fragment>
                    <CardStyle>
                        <CardHeader title='전체 사용자'/>
                        <CardContent>
                            <TotalStyle variant='h6'>{getNumber(data.todayTotalUserCount)}</TotalStyle>
                            <FlexColumn>
                                <SummaryTitle>전일대비</SummaryTitle>
                                <SummaryContents>
                                    <Typography sx={{color: (data.todayTotalUserCount < data.yesterdayTotalUserCount) && '#EC2D47!important'}}>{(data.todayTotalUserCount === data.yesterdayTotalUserCount) ? '----' : getNumber(data.todayTotalUserCount - data.yesterdayTotalUserCount)}</Typography>
                                    <Gutter />
                                    <Typography sx={{color: (data.todayTotalUserCount < data.yesterdayTotalUserCount) && '#EC2D47!important'}}>
                                        {(data.todayTotalUserCount < data.yesterdayTotalUserCount) ?
                                            <ArrowDownIcon/> :
                                            (data.todayTotalUserCount > data.yesterdayTotalUserCount) ?
                                                <ArrowUpIcon/>
                                                :
                                                <ArrowMinusIcon/>
                                        }
                                        {getRate(data.yesterdayTotalUserCount, data.todayTotalUserCount)}%</Typography>
                                </SummaryContents>
                            </FlexColumn>
                        </CardContent>
                    </CardStyle>

                    <CardStyle>
                        <CardHeader title='신규 사용자'/>
                        <CardContent>
                            <TotalStyle variant='h6'>{getNumber(data.todayNewUserCount)}</TotalStyle>
                            <FlexColumn>
                                <SummaryTitle>전일대비</SummaryTitle>
                                <SummaryContents>
                                    <Typography sx={{color: (data.todayNewUserCount < data.yesterdayNewUserCount) && '#EC2D47!important'}}>{(data.todayNewUserCount === data.yesterdayNewUserCount) ? '----' : getNumber(data.todayNewUserCount - data.yesterdayNewUserCount)}</Typography>
                                    <Gutter />
                                    <Typography sx={{color: (data.todayNewUserCount < data.yesterdayNewUserCount) && '#EC2D47!important'}}>
                                        {(data.todayNewUserCount < data.yesterdayNewUserCount) ?
                                            <ArrowDownIcon/> :
                                            (data.todayNewUserCount > data.yesterdayNewUserCount) ?
                                                <ArrowUpIcon/>
                                                :
                                                <ArrowMinusIcon/>
                                        }
                                        {getRate(data.yesterdayNewUserCount, data.todayNewUserCount)}%</Typography>
                                </SummaryContents>
                            </FlexColumn>
                        </CardContent>
                    </CardStyle>

                    <CardStyle>
                        <CardHeader title='활성 사용자'/>
                        <CardContent>
                            <TotalStyle variant='h6'>{getNumber(data.todayActiveUserCount)}</TotalStyle>
                            <FlexColumn>
                                <SummaryTitle>전일대비</SummaryTitle>
                                <SummaryContents>
                                    <Typography sx={{color: (data.todayActiveUserCount < data.yesterdayActiveUserCount) && '#EC2D47!important'}}>{(data.todayActiveUserCount === data.yesterdayActiveUserCount) ? '----' : getNumber(data.todayActiveUserCount - data.yesterdayActiveUserCount)}</Typography>
                                    <Gutter />
                                    <Typography sx={{color: (data.todayActiveUserCount < data.yesterdayActiveUserCount) && '#EC2D47!important'}}>
                                        {(data.todayActiveUserCount < data.yesterdayActiveUserCount) ?
                                            <ArrowDownIcon/> :
                                            (data.todayActiveUserCount > data.yesterdayActiveUserCount) ?
                                                <ArrowUpIcon/>
                                                :
                                                <ArrowMinusIcon/>
                                        }
                                        {getRate(data.yesterdayActiveUserCount, data.todayActiveUserCount)}%</Typography>
                                </SummaryContents>
                            </FlexColumn>
                        </CardContent>
                    </CardStyle>

                    <CardStyle>
                        <CardHeader title='오늘 대화량'/>
                        <CardContent>
                            <TotalStyle variant='h6'>{getNumber(data.todayDialogCount)}</TotalStyle>
                            <FlexColumn>
                                <SummaryTitle>전일대비</SummaryTitle>
                                <SummaryContents>
                                    <Typography sx={{color: (data.todayDialogCount < data.yesterdayDialogCount) && '#EC2D47!important'}}>{(data.todayDialogCount === data.yesterdayDialogCount) ? '----' : getNumber(data.todayDialogCount - data.yesterdayDialogCount)}</Typography>
                                    <Gutter />
                                    <Typography sx={{color: (data.todayDialogCount < data.yesterdayDialogCount) && '#EC2D47!important'}}>
                                        {(data.todayDialogCount < data.yesterdayDialogCount) ?
                                            <ArrowDownIcon/> :
                                            (data.todayDialogCount > data.yesterdayDialogCount) ?
                                                <ArrowUpIcon/>
                                                :
                                                <ArrowMinusIcon/>
                                        }
                                        {getRate(data.yesterdayDialogCount, data.todayDialogCount)}%</Typography>
                                </SummaryContents>
                            </FlexColumn>
                        </CardContent>
                    </CardStyle>

                    <CardStyle>
                        <CardHeader title='오늘 이용시간'/>
                        <CardContent>
                            <TotalStyle variant='h6'>{getTime(data.todayUsageTime)}</TotalStyle>
                            <FlexColumn>
                                <SummaryTitle>전일대비</SummaryTitle>
                                <SummaryContents>
                                    <Typography sx={{color: (data.todayUsageTime < data.yesterdayUsageTime) && '#EC2D47!important'}}>{(data.todayUsageTime === data.yesterdayUsageTime) ? '----' : getTime(data.todayUsageTime - data.yesterdayUsageTime)}</Typography>
                                    <Gutter />
                                    <Typography sx={{color: (data.todayUsageTime < data.yesterdayUsageTime) && '#EC2D47!important'}}>
                                        {(data.todayUsageTime < data.yesterdayUsageTime) ?
                                            <ArrowDownIcon/> :
                                            (data.todayUsageTime > data.yesterdayUsageTime) ?
                                                <ArrowUpIcon/>
                                                :
                                                <ArrowMinusIcon/>
                                        }
                                        {getRate(data.yesterdayUsageTime, data.todayUsageTime)}%</Typography>
                                </SummaryContents>
                            </FlexColumn>
                        </CardContent>
                    </CardStyle>
                </React.Fragment>
                :
                null
            }

        </Root>
    );
}

export default inject('dashboardStore')(observer(MainSummaryComponent));
