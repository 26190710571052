import React, {useEffect} from "react";
import { Box, AppBar, Toolbar, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Sidebar from "./Sidebar";
import { ReactComponent as LogoBi } from "../Common/Images/LogoBi.svg";
import { ReactComponent as MenuIcon } from "../Common/Images/MenuIcon.svg";
import { ReactComponent as HeartRateIcon } from "../Common/Images/HeartRateIcon.svg";
import { ReactComponent as UserSearchIcon } from "../Common/Images/UserSearchIcon.svg";
import { ReactComponent as ComparisonIcon } from "../Common/Images/ComparisonIcon.svg";
import { ReactComponent as NprojMangeIcon } from "../Common/Images/NprojMangeIcon.svg";
import { ReactComponent as Ncp2MangeIcon } from "../Common/Images/Ncp2MangeIcon.svg";
import { ReactComponent as FirmwareMangeIcon } from "../Common/Images/FirmwareMangeIcon.svg";
import DashboardMainComponent from "./DashboardMainComponent";
import UserSearch from "./UserSearch";
import ConversationComparison from "./ConversationComparison";
import {inject, observer} from "mobx-react";
import {Flex} from "../Common/CommonStyle";
import NprojManage from "../NcodeEdit/NprojManage";
import Ncp2Manage from "../NcodeEdit/Ncp2Manage";
import FirmwareManage from "../NcodeEdit/FirmwareManage"


const Root = styled(Box)(() => ({
    width: "100%",
    height: "max-content",
}));


const AppBarStyle = styled(AppBar)(() => ({
    display:'none',
    position:'static',
    height:60,
    padding:'0 40px',
    backgroundColor:'#fff',
    boxShadow:'none',
    zIndex:999,
    '& .MuiToolbar-root':{
        height:60,
        minHeight:60,
        justifyContent:'space-between',
        padding:0,
    },
    '@media all and (max-width: 1280px)': {
        display:'flex',
    },
    '@media all and (max-width: 768px)': {
        padding:'0 16px',
    },
}));

const LogoBox = styled(Flex)(() => ({
    '& svg':{
        width:120,
        height:27,
    },
}));


const Wrap = styled(Box)(() => ({
    width: "100%",
    height: "max-content",
    display:'flex',
    backgroundColor:'#FFFAE7',
    '@media all and (max-width: 1280px)': {
        flexDirection:'row-reverse',
    },
}));

const Container = styled(Box)(() => ({
    // minWidth: `calc(100vw - ${sideBarWidth}px)`,
    width:'100%',//1460
    minHeight: "calc(100vh - 60px)",
    // maxHeight:'100vh',
    backgroundColor:'#FFFAE7',
    padding:80,
    overflow:'auto',
    boxSizing:'border-box',
    '@media all and (max-width: 1980px)': {
        width: '100%',
    },
    '@media all and (max-width: 1280px)': {
        padding:60,
    },
    '@media all and (max-width: 1024px) and (min-width: 769px)': {
        padding:40,
    },
    '@media all and (max-width: 768px)': {
        padding:'40px 16px',
    },
}));


export const sideBarWidth = 300;

function Dashboard(props) {

    const {authStore, dashboardStore, loadingStore} = props;

    useEffect(() => {
        loadingStore.setIsLoading(dashboardStore.getIsLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardStore.getIsLoading]);

    const [sidebarValue, setSidebarValue] = React.useState(0);
    const handleChangeMenu = (newValue, isInit) => {
        if (isInit) {
            dashboardStore.init();
        }
        setSidebarValue(newValue);
    };

    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    const lnbMenu = [
        {
            icon: <HeartRateIcon/>,
            text: '대시보드'
        },
        {
            icon: <UserSearchIcon/>,
            text: '유저검색'
        },
        {
            icon: <ComparisonIcon/>,
            text: '교재/자유대화 비교'
        },
        {
           category: 'divider'
        }
    ];

    if (authStore.loginUser.type === 'RootAdmin') {
        lnbMenu.push({
            icon: <NprojMangeIcon />,
            text: 'Nproj 관리'
        });
        lnbMenu.push({
            icon: <Ncp2MangeIcon />,
            text: 'Ncp2 관리'
        });
        lnbMenu.push({
            icon: <FirmwareMangeIcon />,
            text: 'Firmware 관리'
        });
    }

    return (
        <Root>
            <AppBarStyle open={open} >
                <Toolbar>
                    <LogoBox>
                        <LogoBi />
                    </LogoBox>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}
                        sx={{ ...(open && { display: 'none' }) }}
                        disableRipple
                    >
                       <MenuIcon/>
                    </IconButton>
                </Toolbar>
            </AppBarStyle>
            <Wrap>
                <Sidebar
                    value={sidebarValue}
                    handleChange={(event, value) => handleChangeMenu(value, true)}
                    lnbMenu={lnbMenu}
                    variant={'permanent'}
                />
                <Sidebar
                    value={sidebarValue}
                    handleChange={(event, value) => handleChangeMenu(value, true)}
                    lnbMenu={lnbMenu}
                    variant={'temporary'}
                    handleDrawerClose={handleDrawerClose}
                    open={open}
                />
                <Container>
                    {sidebarValue === 0 &&
                        <DashboardMainComponent handleChangeMenu={handleChangeMenu}/>
                    }
                    {sidebarValue === 1 &&
                        <UserSearch/>
                    }
                    {sidebarValue === 2 &&
                        <ConversationComparison/>
                    }
                    {sidebarValue === 4 &&
                        <NprojManage/>
                    }
                    {sidebarValue === 5 &&
                        <Ncp2Manage/>
                    }
                    {sidebarValue === 6 &&
                        <FirmwareManage/>
                    }
                    
                </Container>
            </Wrap>
        </Root>
    );
}

export default inject('authStore', 'dashboardStore', 'loadingStore')(observer(Dashboard));


