import React from "react";
import {Box, Select} from "@mui/material";
import { Menu, SelectFormControlBox} from "./CommonStyle";
import { ReactComponent as ChevronDownIcon } from "./Images/ChevronDownIcon.svg";

export default function CommonSelect(props) {
    const { width, filter, handleChangeFilter, menuList, disabled } = props;

    return (
                  <SelectFormControlBox variant="outlined" sx={{width:{width}}}>
                      <Select
                          id="filter"
                          value={filter}
                          onChange={handleChangeFilter}
                          disabled={disabled}
                          IconComponent={(props) => (
                              <Box>
                                  <ChevronDownIcon style={{marginTop:4, marginRight:8,}} />
                              </Box>
                          )}
                          MenuProps={{
                              anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left'
                              },
                              transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left'
                              },
                              PaperProps: {
                                  sx: {
                                      '&.MuiPaper-root': {
                                          boxShadow:' 0px 0px 4px rgba(0, 0, 0, 0.15);',
                                          border: '0',
                                          borderRadius:'4px',
                                          boxSizing: 'border-box',
                                          marginTop: '4px',
                                          '& ul': {
                                              padding: 0
                                          }
                                      }
                                  }
                              }
                          }}
                      >
                          {menuList.map((list, i) => (
                              <Menu key={i} value={list.value} disableRipple>
                                  {list.text}
                              </Menu>
                          ))}
                      </Select>
                  </SelectFormControlBox>
    );
}


