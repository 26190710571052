import { styled } from '@mui/material/styles';
import {
    Box,
    Button,
    TextField,
    FormControl,
    Typography,
    Card,
    MenuItem,
    Table,
    Pagination,
    Dialog,
    Popover
} from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';

export const StyledInput = styled(TextField)(({ theme, width, height, fontSize}) => ({
    '&.MuiFormControl-root': {
        width: width ? width : 'calc(100% - 2px)',
        boxSizing: 'border-box'
    },
    '& .MuiInputBase-root': {
        width: '100%',
        minHeight: 40,
        height: height ? height : 52,
        background: '#fff',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '8px 15px !important',
        '& input': {
            padding: '0 !important',
            fontSize: fontSize ? fontSize : '1rem',
            color: '#333',
            fontWeight: 400,
            opacity: 1,
            lineHeight: '22.4px',
            '&::placeholder': {
                color: '#C3C3C3',
                opacity: 1,
            }
        },
        '& .MuiOutlinedInput-notchedOutline': {
            width: '100%',
            border: '1px solid #C9C9CA',
            boxSizing: 'border-box'
        },
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #C9C9CA'
            }
        },
        '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #F9CB28'
            }
        },
        '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #F64860',
            },
        },

    }
}));

export const SelectFormControlBox = styled(FormControl)(({ theme }) => ({
    '&.MuiFormControl-root': {
        // minWidth: 'calc(100% - 2px)',
        marginRight: 0,
        justifyContent:'center',
        '@media all and (max-width: 768px) ': {
            width:'100%',
        },
        '& .MuiInputBase-root': {
           // width:width ? width : 161,
            minHeight: 33,
        },
        '& .MuiSelect-select': {
            width: '100%',
            boxSizing: 'border-box',
            padding: '8px!important',
            fontSize: '0.875rem',
            color: '#333',
            fontWeight: 400,
            lineHeight: '19.6px',
            background: '#fff'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            left: 0
        },
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #C9C9CA',
                borderRadius: 4
            },
        '& svg': {
            marginTop: -4,
            right: 10,
            '&.Mui-disabled': {
                '& path': {
                    fill: '#BBBBBB'
                }
            }
        },
        '&.Mui-disabled': {
            '& .MuiSelect-select': {
                color: '#BBBBBB'
            },
            '& input': {
                color: '#BBBBBB'
            },
            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #BBBBBB'
            }
        }
    }
}));

export const Menu = styled(MenuItem)(({ theme }) => ({
    '&.MuiMenuItem-root': {
        width: '100%',
        fontSize: '0.875rem',
        color: '#111',
        boxSizing: 'border-box',
        height: 33,
        borderRadius: 0,
        padding: '0 12px',
        '&:hover': {
            background: '#FFEA7A'
        },
        '&.Mui-selected': {
            background: '#FFEA7A',
            fontWeight: 400
        },
        '&.Mui-disabled': {
            color: '#d9d9d9'
        }
    }
}));

export const Flex = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center'
}));

export const FlexColumn = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
}));

export const FlexColumnCenter = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

export const FlexSpace = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
}));

export const FlexCenter = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

export const FlexEnd = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
}));

export const ButtonStyle = styled(Button)(({ theme, width, height, background, border, borderRadius, hoverBk, hoverColor }) => ({
    '&.MuiButtonBase-root': {
        minWidth: width ? width : '100%',
        height: height,
        padding: '0 8px',
        background: background,
        border: border ? `1px solid ${border}` : background,
        borderRadius: borderRadius ? borderRadius : 4,
        boxSizing: 'border-box',
        '&:hover': {
            background: hoverBk ? hoverBk : background,
            border: hoverBk ? `1px solid ${hoverBk}` : border ? `1px solid ${border}` : background,
            '& p': {
                color: hoverColor
            }
        },
        '& .MuiButton-startIcon': {
            marginLeft: 0
        },
        '&.Mui-disabled': {
            background: '#DAE1E9',
            border: '1px solid #DAE1E9',
            '& p': {
                color: '#979DA4'
            },
            '& .arrow-clockwise': {
                fill: '#979DA4'
            }
        }
    }
}));

export const ButtonStyleText = styled(Typography)(({ theme, fontSize, fontWeight, color}) => ({
    '&.MuiTypography-root': {
        fontSize: fontSize ? fontSize : '1rem',
        fontWeight: fontWeight ? fontWeight : 400,
        color: color ? color : '#333',
    },
}));



export const PageTitle = styled(Typography)(({ theme,  color, fontSize, fontWeight, mb  }) => ({
    '&.MuiTypography-root': {
        color: color ? color : '#FDD751',
        fontSize: fontSize ? fontSize :'2rem',
        fontWeight: fontWeight ? fontWeight :700,
        textTransform: 'none',
        marginBottom: mb ? mb :60,
        '@media all and (max-width: 1280px)': {
            marginBottom: mb ? mb :40,
        },
        '@media all and (max-width: 1024px)': {
            fontSize: '2.25rem',
        },
        '@media all and (max-width: 768px)': {
            marginBottom: mb ? mb :24,
        },
    }
}));

export const TextStyle = styled(Typography)(({ theme, color, fontSize, fontWeight }) => ({
    '&.MuiTypography-root': {
        color: color ? color : '#fff',
        fontSize: fontSize ? fontSize : '1.125rem',
        fontWeight: fontWeight ? fontWeight : 600,
        textTransform: 'none',
    }
}));

export const CardStyle = styled(Card)(({ theme}) => ({
    background:'#fff',
    boxShadow:'2px 2px 8px rgba(0, 0, 0, 0.15)',
    borderRadius:16,
    '& .MuiCardHeader-root': {
        padding:'24px 24px 16px 24px',
        borderBottom:'1px solid #F0F1F5',
        '& .MuiCardHeader-title': {
            color: '#333',
            fontSize: '1.25rem',
            fontWeight: 700,
            lineHeight: '28px',
        },
        '& .MuiCardHeader-subheader':{
            color:'#71717a',
            fontSize:'0.875rem',
            lineHeight:'19.6px',
        },
    },
    '& .MuiCardContent-root': {
        padding:'16px 24px 24px 24px',
    },
    '@media all and (max-width: 1280px) and (min-width: 1023px)': {
        '& .MuiCardHeader-root': {
            '& .MuiCardHeader-subheader':{
                fontSize:'0.8rem',
            },
        }
    },
    '@media all and (max-width: 1024px)': {
        '& .MuiCardHeader-root': {
            padding:'16px 16px 8px 16px',
            '& .MuiCardHeader-title': {
                fontSize: '1.188rem',
            },
            '& .MuiCardHeader-subheader':{
                fontSize:'0.875rem',
            },
        },
        '& .MuiCardContent-root': {
            padding:'8px 16px 16px 16px',
        },
    },
    '@media all and (max-width: 768px)': {
        '& .MuiCardHeader-root': {
            '& .MuiCardHeader-subheader': {
                fontSize: '0.9rem',
            },
        },
    },
}));

export const TableStyle = styled(Table)(({ theme,  }) => ({
    '& .MuiTableRow-head':{
        '& .MuiTableCell-head':{
            borderBottom:'1px solid #F0F1F5',
            borderTop:'1px solid #F0F1F5',
            background:'#FBFCFE',
            fontWeight:400,
        }
    },
    '& .MuiTableCell-body, & .MuiTableCell-body:last-child th':{
        borderBottom:'1px solid #F0F1F5!important',
        background:'#fff',
    },
}));

export const PaginationStyle = styled(Pagination)(() => ({
    width:'100%',
    display:'flex',
    justifyContent : 'center',
    '& .MuiPagination-root':{
        margin:'0 auto',
    },
    '& .MuiPaginationItem-root':{
        fontSize:'0.75rem',
        color:'#333',
        '&.Mui-selected, &:hover':{
            background:'#FDD751',
            color:'#fff',
        },
    },
}));

export const DialogBox = styled(Dialog)(({ theme }) => ({
    "& .MuiBackdrop-root": {
        background: "rgba(0, 0, 0, 0.8)",
    },
    "& .MuiPaper-root": {
        minWidth: 328,
        background: "#fff",
        padding: "32px 24px",
        boxSizing: "border-box",
        borderRadius: 20,
    },
}));

export const DialogTitleText = styled(Typography)(({ theme }) => ({
    "&.MuiTypography-root": {
        fontSize: "1.125rem",
        color: "#333",
        fontWeight: 700,
        lineHeight: "25.2px",
        textAlign: "center",
    },
}));

export const DialogLoadingBox = styled(Box)(() => ({
    margin: "25px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .CircularProgressbar": {
        width: 50,
    },
    "& .CircularProgressbar-trail": {
        stroke: "#F0F1F5",
    },
    "& .CircularProgressbar-path": {
        stroke: "#FDD751",
    },
    "& .CircularProgressbar-text": {
        fontSize: "1.625rem",
        fill: "#909195",
        dominantBaseline: "central",
        fontWeight: 500,
    },
}));

export const DialogLoader = styled('span')(() => ({
    position:'relative',
    width : 48,
    height: 48,
    borderRadius: '50%',
    display: 'inline-block',
    border: '5px solid #F0F1F5',
    marginBottom:32,
    boxSizing:'border-box',
    '& .spinner':{
        position: 'absolute',
        top: -5,
        left: -5,
        width: 48,
        height: 48,
    },
    '& .path': {
        position:'absolute',
        top:0,
        left:0,
        stroke: '#FDD751',
        strokeLinecap: 'round',
        animation: `dash 1s ease-in-out infinite`,
    },
    '@keyframes rotate': {
        '0%': { transform: 'rotate(0deg)' },
        '100%' :{transform: 'rotate(360deg)'},
    },
    '@keyframes dash':{
        '0%': {
            strokeDasharray: '1, 150',
            strokeDashoffset: '0',
        },
        '50%': {
            strokeDasharray: '90, 150',
            strokeDashoffset: -35,
        },
        '100%': {
            strokeDasharray: '90, 150',
            strokeDashoffset: -124,
        }
    }
}));


export const DatePickerBox = styled(Popover)(({ theme }) => ({
    '& .MuiPaper-root': {
        padding:16,
        borderRadius: 4,
        background: '#fff',
    }
}));

export const DateCalendarBox = styled(DateCalendar)(({ theme }) => ({
    '&.MuiDateCalendar-root': {
        width: 'unset',
        maxHeight: 'auto',
        height: 'auto',
        padding:0,
        borderRadius:4,
        '& .MuiPickersCalendarHeader-root': {
            marginTop: 0,
            marginBottom: 10,
            padding: '0 0 0 10px',
            position: 'relative',
            '& .MuiPickersArrowSwitcher-button:hover':{
                backgroundColor:'transparent',
            },
            '& .MuiPickersCalendarHeader-labelContainer': {
                '& .MuiPickersFadeTransitionGroup-root': {
                    '& > div': {
                        fontSize: '0.875rem',
                        color: '#49454F',
                        fontWeight: 500,
                        marginRight: 0
                    }
                },
                '& .MuiPickersCalendarHeader-switchViewIcon':{
                    width:18,
                    height:18,
                },
            }
        },
        '& .MuiDayCalendar-header': {
            '& .MuiDayCalendar-weekDayLabel': {
                width: 40,
                height: 40,
                fontSize: '1rem',
                color: '#333',
                fontWeight: 400,
                margin: 0,
            }
        },
        '& .MuiDayCalendar-slideTransition': {
            height: 'auto',
            minHeight: 254,
            '& .MuiPickersDay-root': {
                width: 40,
                height: 40,
                fontSize: '1rem',
                color: '#333',
                fontWeight: 400,
                margin: 0,
                '&.MuiPickersDay-today': {
                    color: '#fff',
                    backgroundColor:'#FDEFBC',
                    border:'0 none',
                    fontWeight: 600
                },
                '&.Mui-selected': {
                    background: '#F9CB28',
                    color: '#fff',
                    fontWeight: 400
                },
                '&.Mui-disabled': {
                    color: '#E0E0E0',
                    fontWeight: 400
                },
                '&:hover, &:focus': {
                    background: '#F9CB28',
                    color: '#fff'
                },
            },
            '& .MuiPickersDay-dayOutsideMonth': {
                color: '#A7A7A7 !important'
            }
        },
        '& .MuiPickersYear-yearButton': {
            width: 72,
            height: 36,
            margin: '16px 0',
            fontSize: '1rem',
            fontWeight:500,
            '&.Mui-selected': {
                background: '#F9CB28',
                color: '#fff'
            }
        },
        '& .MuiYearCalendar-root': {
            width: 264,
            height: 272,
            maxHeight: 272,
            boxSizing: 'border-box',
            '&::-webkit-scrollbar': {
                width: '5px'
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#7A7A7A',
                borderRadius: '10px',
                backgroundClip: 'padding-box'
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: '10px',
                background: '#E8E8E8'
            }
        }
    },
}));