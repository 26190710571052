import React from "react";
import {TableRow, TableHead, TableCell, TableBody, Chip, Tooltip, Typography, Box, IconButton, Popover} from "@mui/material";
import { styled } from "@mui/material/styles";
import {Flex, Menu, TableStyle} from '../Common/CommonStyle';
import { ReactComponent as FilterLeftIcon } from "../Common/Images/FilterLeftIcon.svg";
import {inject, observer} from "mobx-react";
import dayjs from "dayjs";

const Root = styled(Box)(() => ({
    width: '100%',
    borderRadius:8,
    overflowY:'hidden',
    overflowX:'auto',
    '& .MuiTable-root':{
        minWidth:1024,
    },
    '& > .MuiButton-root':{
        display:'flex',
        margin:'0 auto',
    },
}));

const ChipStyle = styled(Chip)(({ theme }) => ({
    minWidth: 48,
    maxWidth: 100,
    height: 28,
    padding: '4px 10px',
    backgroundColor: '#FFFAE7',
    border:'1px solid #F9CB28',
    borderRadius: 25,
    lineHeight: 'initial',
    boxSizing: 'border-box',
    display: 'box',
    overflow:'hidden',
    verticalAlign:'top',
    textOverflow: 'ellipsis',
    wordBreak:'break-all',
    '-webkit-box-orient':'vertical',
    '-webkit-line-clamp':'1',
    '& .MuiChip-label': {
        padding: 0,
        color: '#666666',
        fontSize: '0.875rem',
        fontWeight: 400
    }
}));
export const Words = styled(Typography)(({ theme}) => ({
    '&.MuiTypography-root': {
        color:'#fff',
        fontSize:'1rem',
        fontWeight:400,
        margin:0,
        padding:0,
        '&:before':{
            content:'","',
            marginRight:4,
        },
        '&:nth-of-type(1):before':{
            content:'""',
            marginRight:0,
        }
    },
}));
export const ButtonStyleText = styled(Typography)(({ theme}) => ({
    '&.MuiTypography-root': {
        fontSize:'1rem',
        fontWeight:'400',
        color: '#333',
    },
}));
export const IconButtonStyle = styled(IconButton)(({ theme}) => ({
    '&.MuiTypography-root': {
        fontSize:'1rem',
        fontWeight:'400',
        color: '#333',
    },
}));

export const PopoverBox = styled(Popover)(({ theme}) => ({
    '&.MuiPaper-root': {
        boxShadow:' 0px 0px 4px rgba(0, 0, 0, 0.15);',
        border: '0',
        borderRadius:'4px',
        boxSizing: 'border-box',
        marginTop: '4px',
        '& ul': {
            padding: 0
        }
    }
}));


export const MenuStyle = styled(Menu)(({ theme}) => ({
    '&.MuiMenuItem-root': {
        minWidth:117,
        fontSize: '0.75rem',
    }
}));


function UserSearchTableComponent(props) {
    const {dashboardStore} = props;

    const userList = dashboardStore.userStatisticsList;

    const getDate = (reportCreatedDatetime) => {
        return dayjs(reportCreatedDatetime).format('YYYY년 M월 D일');
    }

    const getTime = (seconds) => {
        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds % 3600) / 60);
        let remainderSeconds = seconds % 60;

        if (remainderSeconds >= 30) {
            minutes += 1;
        }

        if (minutes >= 60) {
            minutes -= 60;
            hours += 1;
        }

        return hours + "시간 " + minutes + "분";
    }

    const getUserId = (userId) => {
        const searchId = dashboardStore.searchId;

        if (searchId === '') {
            return userId;
        } else {
            const highlightStartIndexList = [];
            const highlightEndIndexList = [];

            let startIndex = 0;
            while (true) {
                const index = userId.indexOf(searchId, startIndex);
                if (index === -1) {
                    break;
                }
                const endIndex = index + searchId.length - 1;

                highlightStartIndexList.push(index);
                highlightEndIndexList.push(endIndex);

                startIndex = index + 1;
            }

            let messageResult = '<div>';

            for (let i = 0; i < userId.length; i++) {
                if (highlightStartIndexList.includes(i) && highlightEndIndexList.includes(i)) {
                    messageResult = messageResult + `<span style="background-color: #E1EFFE">` + userId[i] + '</span>';
                } else if (highlightStartIndexList.includes(i)) {
                    messageResult = messageResult + `<span style="background-color: #E1EFFE">` + userId[i];
                } else if (highlightEndIndexList.includes(i)) {
                    messageResult = messageResult + userId[i] + `</span>`;
                } else {
                    messageResult = messageResult + userId[i];
                }
            }
            messageResult = messageResult + '</div>';

            return <div dangerouslySetInnerHTML={{ __html: messageResult }} />;
        }
    }

    const [filterDate, setFilterDate] = React.useState(null);
    const [filterQuantity, setFilterQuantity] = React.useState(null);
    const [filterTime, setFilterTime] = React.useState(null);

    const handleClickFilterDate = (event) => {
        setFilterDate(event.currentTarget);
    };
    const handleClickFilterQuantity = (event) => {
        setFilterQuantity(event.currentTarget);
    };
    const handleClickFilterTime = (event) => {
        setFilterTime(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setFilterDate(null);
        setFilterQuantity(null);
        setFilterTime(null);
    };

    const openDate = Boolean(filterDate);
    const openQuantity = Boolean(filterQuantity);
    const openTime = Boolean(filterTime);

    const sortDate = [
        { text: '최신순', value: 'descending' }, { text: '오래된순', value: 'ascending'}
    ];

    const sortQuantity = [
        { text: '대화량 많은 순', value: 'descending' }, { text: '대화량 적은 순', value: 'ascending'}
    ];

    const sortTime = [
        { text: '총 이용시간 많은 순', value: 'descending' }, { text: '총 이용시간 적은 순', value: 'ascending'}
    ];

    const handleClickLastTimeSort = (value) => {
        handleCloseFilter();
        dashboardStore.setUserSortColumn('lastUsedDatetime');
        dashboardStore.setUserSortDirection(value);
        dashboardStore.getUserStatistics();
    }

    const handleClickSortDialogCount = (value) => {
        handleCloseFilter();
        dashboardStore.setUserSortColumn('totalDialogCount');
        dashboardStore.setUserSortDirection(value);
        dashboardStore.getUserStatistics();
    }

    const handleClickSortUseTime = (value) => {
        handleCloseFilter();
        dashboardStore.setUserSortColumn('totalUsageTime');
        dashboardStore.setUserSortDirection(value);
        dashboardStore.getUserStatistics();
    }

    return (
        <Root>
            <TableStyle>
                <TableHead>
                    <TableRow>
                        <TableCell align="center"  sx={{ width: '6%' }}>No.</TableCell>
                        <TableCell align="left" sx={{ width: '16%' }}>이메일 계정</TableCell>
                        <TableCell align="left" sx={{ width: '16%' }}>마지막 사용 시간
                            <IconButtonStyle disableRipple
                                             onClick={handleClickFilterDate}
                            ><FilterLeftIcon/></IconButtonStyle></TableCell>
                        <TableCell align="left" sx={{ width: '16%' }}>총 대화량 (누적)
                            <IconButtonStyle disableRipple
                                             onClick={handleClickFilterQuantity}
                            ><FilterLeftIcon/></IconButtonStyle>
                        </TableCell>
                        <TableCell align="left" sx={{ width: '16%' }}>총 이용시간 (누적)
                            <IconButtonStyle disableRipple
                                             onClick={handleClickFilterTime}
                            ><FilterLeftIcon/></IconButtonStyle>
                        </TableCell>
                        <TableCell align="left" sx={{ width: '30%' }}>가장 많이 말한 단어</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userList.map((user, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="center" component="th" scope="row">
                                {((dashboardStore.page -1) * dashboardStore.rowsPerPage) + i + 1}
                            </TableCell>
                            <TableCell align="left">{getUserId(user.userId)}</TableCell>
                            <TableCell align="left">{getDate(user.lastUsedDatetime)}</TableCell>
                            <TableCell align="left">{user.totalDialogCount}회</TableCell>
                            <TableCell align="left">{getTime(user.totalUsageTime)}</TableCell>
                            <TableCell align="left">
                                <Tooltip
                                    componentsProps={{
                                        tooltip:{
                                            sx: {
                                                maxWidth:'500px!important',
                                                backgroundColor: '#333',
                                                padding:'16px 24px',
                                                borderRadius:1,
                                                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.20)',
                                    },
                                    },
                                    }}
                                    title={
                                    <Flex>
                                        {user.topicWordList.map((word, i) => (
                                            <Words key={i}>
                                                {word}
                                            </Words>
                                        ))}
                                    </Flex>
                                } placement="bottom-start">
                                    <Flex gap={1} style={{maxWidth:'350px'}}>
                                        {user.topicWordList.map((word, i) => (
                                                <ChipStyle
                                                    label={word}
                                                    key={i}
                                                />
                                        ))}
                                    </Flex>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </TableStyle>
            <PopoverBox
                open={openDate}
                anchorEl={filterDate}
                onClose={handleCloseFilter}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {sortDate.map((list, i) => (
                    <MenuStyle key={i} value={i} onClick={() => handleClickLastTimeSort(list.value)} disableRipple>
                        {list.text}
                    </MenuStyle>
                ))}
            </PopoverBox>
            <PopoverBox
                open={openQuantity}
                anchorEl={filterQuantity}
                onClose={handleCloseFilter}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {sortQuantity.map((list, i) => (
                    <MenuStyle key={i} value={i} onClick={() => handleClickSortDialogCount(list.value)} disableRipple>
                        {list.text}
                    </MenuStyle>
                ))}
            </PopoverBox>

            <PopoverBox
                open={openTime}
                anchorEl={filterTime}
                onClose={handleCloseFilter}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {sortTime.map((list, i) => (
                    <MenuStyle key={i} value={i} onClick={() => handleClickSortUseTime(list.value)} disableRipple>
                        {list.text}
                    </MenuStyle>
                ))}
            </PopoverBox>




        </Root>
    );
}

export default inject('dashboardStore')(observer(UserSearchTableComponent));
