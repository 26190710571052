import React from "react";
import {Tab, Tabs, Tooltip} from "@mui/material";
import { styled } from "@mui/material/styles";
import {CardStyle, FlexColumnCenter, FlexSpace} from "../Common/CommonStyle";
import {ReactComponent as ExclamationCircleIcon} from "../Common/Images/ExclamationCircleIcon.svg";
import LineChartComponent from "./Component/LineChartComponent";
import {inject, observer} from "mobx-react";
import SelectDatepickerDialog from "./Dialog/SelectDatepickerDialog";

const Root = styled(CardStyle)(() => ({
    width: '100%',
    '@media all and (max-width: 768px) ': {
        '& .MuiFormControl-root':{
            width:'100%',
        },
    },
}));


const Contents = styled(FlexColumnCenter)(() => ({
    padding:32,

}));

// const EmptyBox = styled(FlexCenter)(() => ({
//     width:'100%',
//     height:259,
//     padding:'16px 0',
//     backgroundColor:'#FBFCFE',
//     color:'#C3C3C3',
//     fontSize:'2.5rem',
//     fontWeight:700,
// }));

const TabTitleBox = styled(FlexSpace)(() => ({
    flexGrow:1,
    alignItems:'flex-end',
    padding:'32px 40px 24px 40px',
    borderBottom:'1px solid #F0F1F5',
    '@media all and (max-width: 1280px)': {
        padding:'32px 32px 24px 32px',
    },
    '@media all and (max-width: 1024px)': {
        padding:'24px 24px 16px 24px',
    },
    '@media all and (max-width: 768px)': {
        flexDirection:'column',
        gap:16,
        padding:'16px 24px',
    },
}));

const TabsStyle = styled(Tabs)(({ theme }) => ({
    minHeight:'auto',
    display:'flex',
    alignItems:'center',
    '& .MuiTabs-indicator':{
        height:4,
        backgroundColor:'#FDD751',
    },
    '& .MuiTabs-flexContainer':{
        gap:32,
    },
    '& .MuiTabScrollButton-root':{
        width:0,
    },
    '@media all and (max-width: 1280px) ': {
        width:518,
        '& .MuiTabScrollButton-root':{
            width:24,
            height:24,
            '&.Mui-disabled':{
                opacity: 0.3
            }
        },
    },
    '@media all and (max-width: 1280px) and (min-width: 1023px)': {
        width:518,
    },
    '@media all and (max-width: 1024px) and (min-width: 769px)': {
        width:415,
    },
    '@media all and (max-width: 768px) ': {
        width:'100%',
        '& .MuiTabs-indicator':{
            display:'none',
        },
        '& .MuiTabs-flexContainer':{
            gap:0,
        },
    },
    '@media all and (max-width: 600px) ': {
        '& .MuiTabScrollButton-root':{
            display:'flex!important',
        }
    },
}));
const TabStyle = styled(Tab)(({ theme }) => ({
    '&.MuiTab-root':{
        minHeight:'auto',
        display:'flex',
        alignItems:'flex-start',
        justifyContent:'flex-start',
        border:'0 none',
        padding:0,
        marginBottom:6,
        color:'#C3C3C3',
        fontSize:'1.25rem',
        fontWeight:700,
        textAlign:'left',
        '&.Mui-selected, &:hover':{
            backgroundColor:'#fff',
            color:'#333',
            '& svg path':{
                stroke:'#333',
            },
        },
        '& svg':{
            width:24,
            height:24,
            marginLeft:'4px!important',
            marginRight:'0px!important',
        },
        '@media all and (max-width: 768px) ': {
            width:'100%',
            maxWidth:'100%',
            justifyContent:'center',
        },
    },
}));

export const TooltipStyle = styled(Tooltip)(({ theme }) => ({

}));


function MainSummaryGraphComponent(props) {
    const {dashboardStore} = props;

    const [tabValue, setTabValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleChangeFilter = (type) => {
        // const filterType = event.target.value;
        dashboardStore.setChartFilterType(type);

        if (type !== 'custom') {
            dashboardStore.getUsageChartStatistics();
        } else {
            dashboardStore.setChartChangeDateType('start');
        }
    };

    const tabList = [
        {text: '전체사용자', icon:false, tooltip:false },
        {text: '신규 가입자', icon:false, tooltip:false },
        {text: '활성 사용자', icon:<ExclamationCircleIcon/>, tooltip:'대화를 하루에 한번이라도 한 사용자 수를 보여줍니다.'},
        {text: '포코로 대화량', icon:<ExclamationCircleIcon/>, tooltip:'유저들이 하루에 대화한 갯수를 모두 합쳐서 보여줍니다.'},
        {text: '포코로 이용시간', icon:<ExclamationCircleIcon/>, tooltip:'유저들이 하루에 포코로를 이용한 시간을 모두 합쳐서 보여줍니다.'},
    ];

    const menuList = [
        { text: '맞춤', value: 'custom'}, { text: '오늘', value: 'today'}, { text: '어제', value: 'yesterday'}, { text: '이번 주', value: 'thisWeek'}, { text: '지난 주', value: 'lastWeek'},
        { text: '지난 7일 동안', value: 'last7Day'}, { text: '지난 14일 동안', value: 'last14Day'}, { text: '지난 30일 동안', value: 'last30Day'}, { text: '지난 60일 동안', value: 'last60Day'},
        { text: '지난 90일 동안', value: 'last90Day'}, { text: '지난 12개월', value: 'last12Month'},
        { text: '올해 (1월부터 오늘까지)', value: 'thisYear'}, { text: '지난해', value: 'lastYear'}
    ];

    return (
          <Root>
              <TabTitleBox>
                  <TabsStyle
                      value={tabValue}
                      onChange={handleChangeTab}
                      scrollButtons
                      variant="scrollable"
                      aria-label="visible arrows tabs example"
                  >
                      {tabList.map((item, i) => (
                              <TabStyle
                                  key={i}
                                  iconPosition="end"
                                  disableRipple
                                  label={
                                      item.icon === false ?
                                          item.text :
                                          <>
                                          {item.text}
                                          <TooltipStyle
                                              componentsProps={{
                                                  tooltip:{
                                                      sx:{
                                                          width:264,
                                                          bgcolor:'#333',
                                                          color:'#fff',
                                                          borderRadius:'8px',
                                                          padding:'16px 24px',
                                                          fontSize:'1rem',
                                                          opacity:1,
                                                  }}
                                          }}
                                              title={item.tooltip}
                                              background={'#333'}
                                          >
                                              {item.icon}</TooltipStyle>
                                          </>
                                  }
                              />
                      ))}
                  </TabsStyle>
                  <SelectDatepickerDialog filter={dashboardStore.chartFilterType} handleChangeFilter={handleChangeFilter} menuList={menuList} width={'200px'} />
              </TabTitleBox>
                <Contents>
                    <LineChartComponent type={tabValue}/>
                </Contents>
          </Root>
    );
}

export default inject('dashboardStore')(observer(MainSummaryGraphComponent));